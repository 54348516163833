import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// Components
import { MainComponent } from './components/main/main.component';
import { PoliticasComponent } from './components/shared/politicas/politicas.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';

// Guard
import { MsalGuard } from '@azure/msal-angular';
import { PermisosGuard } from './guards/permisos.guard';

const routes: Routes = [
  { path: '', component: MainComponent },
  { path: 'unsubscribe', loadChildren: () => import('./components/shared/unsubscribe/unsubscribe.module').then(m => m.UnsubscribeModule) },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [
      MsalGuard
    ],
    children: [
      { path: 'initial-configuration', loadChildren: () => import('./components/dashboard/configuracioninicial/configuracioninicial.module').then(m => m.ConfiguracioninicialModule), canActivate: [PermisosGuard] },
      { path: 'web', loadChildren: () => import('./components/dashboard/web/casosdeuso-web/casosdeuso-web.module').then(m => m.CasosdeusoWebModule), canActivate: [PermisosGuard] },
      { path: 'kiosco', loadChildren: () => import('./components/dashboard/kiosco/casosdeuso-kiosco/casosdeuso-kiosco.module').then(m => m.CasosdeusoKioscoModule), canActivate: [PermisosGuard] },
      { path: 'narradora', loadChildren: () => import('./components/dashboard/speaker/casosdeuso-speaker/casosdeuso-speaker.module').then(m => m.CasosdeusoSpeakerModule), canActivate: [PermisosGuard]},
      { path: 'whatsapp', loadChildren: () => import('./components/dashboard/whatsapp/casosdeuso-whatsapp/casosdeuso-whatsapp.module').then(m => m.CasosdeusoWhatsappModule) },
      { path: 'billing', loadChildren: () => import('./components/dashboard/facturacion/facturacion.module').then(m => m.FacturacionModule), canActivate: [PermisosGuard] },
      { path: 'suscripcion', loadChildren: () => import('./components/dashboard/facturacion/suscripcion/suscripcion.module').then(m => m.SuscripcionModule), canActivate: [PermisosGuard] },
      { path: 'instructive', loadChildren: () => import('./components/dashboard/instructivos/instructivos.module').then(m => m.InstructivosModule) },
      { path: 'users', loadChildren: () => import('./components/dashboard/usuarios/usuarios.module').then(m => m.UsuariosModule), canActivate: [PermisosGuard] },
      { path: 'estadisticas', loadChildren: () => import('./components/dashboard/estadisticas/estadisticas.module').then(m => m.EstadisticasModule), canActivate: [PermisosGuard] },
      { path: 'metrics', loadChildren: () => import('./components/dashboard/metricas/metricas.module').then(m => m.MetricasModule), canActivate: [PermisosGuard] },
      { path: '**', redirectTo: 'web' }
    ]
  },
  { path: 'blog', loadChildren: () => import('./components/blog/blog.module').then(m => m.BlogModule) },
  { path: 'blog/principales-kpis-servicio-al-cliente', loadChildren: () => import('./components/blog/principales-kpis/principales-kpis.module').then(m => m.PrincipalesKpisModule) },
  { path: 'blog/que-es-nati', loadChildren: () => import('./components/blog/article2/article2-routing.module').then(m => m.Article2Module) },
  { path: 'politicas', component: PoliticasComponent },
  { path: '**', redirectTo: '' }
];

const isIframe = window !== window.parent && !window.opener;

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: false,
    scrollPositionRestoration: 'enabled',
    initialNavigation: !isIframe ? 'enabledBlocking' : 'disabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Component, HostListener, OnInit, OnDestroy, Inject, ViewChild, ElementRef, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ViewportScroller } from '@angular/common';
import AOS from 'aos';
import { Planes, Datum } from 'src/app/models/facturacion/obtener-planes.model';
import { ObtenerPlanesService } from 'src/app/services/facturacion/obtener-planes.service';

import { CarouselService } from 'src/app/services/carousel/carousel.service'
import { Carousel } from 'src/app/models/carousel/carousel.model'

import { PrimeNGConfig } from 'primeng/api';

import { MsalService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { PopupRequest, RedirectRequest, } from '@azure/msal-browser';
import { b2cPolicies } from '../../auth-config';

import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit, OnDestroy {
  subscriptions$: Subscription[] = [];

  UrlBaseB2C = 'https://natisaas.onmicrosoft.com/c2d47157-26fb-41da-9d54-39355f077001/';
  ScopeApiRead = 'Api.Read';

  showSectionWeb = false;
  showSectionKiosco = false;
  showSectionWhatsapp = false;

  modalPlanFree?: BsModalRef;
  modalPlans?: BsModalRef;
  modalPlanAnual?: BsModalRef;
  planSelected: string = '';
  planSelectedAnual: string = '';
  frecuenciaSelected: string = '';
  frecuenciaSelectedAnual: string = '';
  planSelectedFormatted: string = '';
  planSelectedAnualFormatted: string = '';
  canalSelected: string = '';
  canalSelectedAnual: string = '';
  planes: Planes | undefined;
  planesCanalWebMensual: Datum[] = [];
  planesCanalKioscoMensual: Datum[] = [];
  planesCanalWhatsappMensual: Datum[] = [];
  planesCanalWebAnual: Datum[] = [];
  planesCanalKioscoAnual: Datum[] = [];
  planesCanalWhatsappAnual: Datum[] = [];
  valorMensualPremiumWhatsapp: any;
  valorMensualEstandarWhatsapp: any;
  valorMensualBasicoWhatsapp: any;
  valorMensualPremiumWeb: any;
  valorMensualEstandarWeb: any;
  valorMensualBasicoWeb: any;

  @ViewChild('modal') modal!: ElementRef;
  cambiarClases = false;
  iconActual = "../../../assets/images/landing/modal-maximizar.png";
  iconMax = "../../../assets/images/landing/modal-maximizar.png";
  iconMin = "../../../assets/images/landing/modal-minimizar.png"

  //burbuja sticky button
  showSticky: boolean = false;
  isMobile = false;
  visible: boolean = false;
  disabledButton = true;

  checkPolitics = new UntypedFormGroup({
    inputPolitics: new UntypedFormControl('', Validators.required)
  });

  lang = localStorage.getItem('language');

  responsiveOptions;
  carousel!: Carousel[];
  carouselBlog!: Carousel[];

  pageYoffset = 0;
  @HostListener('window:scroll', ['$event']) onScroll() {
    this.pageYoffset = window.pageYOffset;
  }

  @HostListener('window:resize', ['$event'])
  onResize(_event: Event) {
    this.checkIfMobile();
  }
  private checkIfMobile() {
    this.isMobile = window.innerWidth < 600;
  }


  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private carouselService: CarouselService,
    private scrolly: ViewportScroller,
    private primengConfig: PrimeNGConfig,
    private translate: TranslateService,
    private modalService: BsModalService,
    private obtenerPlanesService: ObtenerPlanesService,
  ) {
    this.responsiveOptions = [
      {
        breakpoint: '1024px',
        numVisible: 3,
        numScroll: 3
      },
      {
        breakpoint: '768px',
        numVisible: 2,
        numScroll: 2
      },
      {
        breakpoint: '560px',
        numVisible: 1,
        numScroll: 1
      }
    ];

  }

  ngOnInit(): void {
    this.checkIfMobile();

    let videoPlay = <HTMLVideoElement>document.getElementById('video-nati');
    videoPlay.muted = true;
    videoPlay.play();

    let videoPlay2 = <HTMLVideoElement>document.getElementById('video-albert');
    videoPlay2.muted = true;
    videoPlay2.play();

    AOS.init();
    AOS.refreshHard();

    this.obtenerPlanes();

    this.primengConfig.ripple = true;

    setTimeout(() => {
      //Get current language and set carousel data
      this.getDataCarousel(this.translate.currentLang);

      //Get language when a change is detected
      this.subscriptions$.push(this.translate.onLangChange.subscribe((event) => {
        this.getDataCarousel(event.lang);
      }));
    }, 0);

  }

  getDataCarousel(langReceived: string) {
    this.carouselService.getCarousel(langReceived).then(carousel => {
      this.carousel = carousel.data;
      this.carouselBlog = carousel.dataBlog;
    })
  }

  scrollToTop() {
    this.scrolly.scrollToPosition([0, 0]);
  }

  changeCheckbox(event: any) {
    if (event.target.checked) {
      this.disabledButton = false;
    } else {
      this.disabledButton = true;
    }
  }

  login(userFlowRequest?: RedirectRequest | PopupRequest) {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest, ...userFlowRequest } as RedirectRequest);
    } else {
      this.authService.loginRedirect(userFlowRequest);
    }

  }

  signup() {
    let signup = {
      scopes: [this.UrlBaseB2C + this.ScopeApiRead],
      authority: b2cPolicies.authorities.signUp.authority
    }

    this.login(signup);
  }

  registerWithPlan(canalSelected: string, planSelected: string, frecuencia: string) {
    const languageCode = localStorage['language'];
    const signup = {
      scopes: [this.UrlBaseB2C + this.ScopeApiRead],
      authority: b2cPolicies.authorities.signUp.authority,
      state: JSON.stringify({ canal: canalSelected, plan: planSelected, frecuencia: frecuencia }),
      extraQueryParameters: {
        ui_locales: languageCode
      }
    };

    this.login(signup);
  }


  mostrarModal() {
    // this.visible = true;
    this.modal.nativeElement.style.display = 'block';
  }

  ocultarModal() {
    this.modal.nativeElement.style.display = 'none'
  }

  maximizarModal() {
    this.cambiarClases = !this.cambiarClases;
    if (this.iconActual == this.iconMax) {
      this.iconActual = this.iconMin;
    } else {
      this.iconActual = this.iconMax;
    }
  }

  showSection(section: string) {
    switch (section) {
      case 'web':
        this.showSectionWeb = true;
        this.showSectionKiosco = false;
        this.showSectionWhatsapp = false;
        break;
      case 'kiosco':
        this.showSectionWeb = false;
        this.showSectionKiosco = true;
        this.showSectionWhatsapp = false;
        break;
      case 'whatsapp':
        this.showSectionWeb = false;
        this.showSectionKiosco = false;
        this.showSectionWhatsapp = true;
        break;
    }

    const sectionPlanes = document.getElementById('section-plans');

    if (this.showSectionWeb || this.showSectionKiosco || this.showSectionWhatsapp) {
      const headerOffset = 140;
      const elementPosition = sectionPlanes?.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
  }

  obtenerPlanes(): void {
    this.subscriptions$.push(this.obtenerPlanesService.obtenerPlanes().subscribe({
      next: (planes: Planes) => {
        this.planes = planes;
        const titulosFiltrados = ['basico', 'estandar', 'premium'];

        const procesarPlanes = (canal, frecuencia) => {
          return planes.data
            .filter(plan => plan.canal === canal && plan.tipoFrecuencia === frecuencia)
            .filter(plan => titulosFiltrados.includes(plan.titulo))
            .sort((a, b) => titulosFiltrados.indexOf(a.titulo) - titulosFiltrados.indexOf(b.titulo))
            .map(plan => ({
              ...plan,
              titulo: this.convertirPrimeraLetraMayuscula(plan.titulo)
            }));
        };

        this.planesCanalWebMensual = procesarPlanes('web', 'mensual');
        this.planesCanalWebAnual = procesarPlanes('web', 'anual');
        this.planesCanalKioscoMensual = procesarPlanes('kiosco', 'mensual');
        this.planesCanalKioscoAnual = procesarPlanes('kiosco', 'anual');
        this.planesCanalWhatsappMensual = procesarPlanes('whatsapp', 'mensual');
        this.planesCanalWhatsappAnual = procesarPlanes('whatsapp', 'anual');

        const calcularValorMensual = (planesAnual, index) => {
          return (planesAnual[index].precioPlan / 12).toFixed(2);
        };

        this.valorMensualBasicoWeb = calcularValorMensual(this.planesCanalWebAnual, 0);
        this.valorMensualEstandarWeb = calcularValorMensual(this.planesCanalWebAnual, 1);
        this.valorMensualPremiumWeb = calcularValorMensual(this.planesCanalWebAnual, 2);

        this.valorMensualBasicoWhatsapp = calcularValorMensual(this.planesCanalWhatsappAnual, 0);
        this.valorMensualEstandarWhatsapp = calcularValorMensual(this.planesCanalWhatsappAnual, 1);
        this.valorMensualPremiumWhatsapp = calcularValorMensual(this.planesCanalWhatsappAnual, 2);
      },
      error: (error) => {
        console.log(error);
      }
    }));
  }

  convertirPrimeraLetraMayuscula(texto: string): string {
    texto = texto.replace('basico', 'Básico').replace('estandar', 'Estándar');
    return texto.charAt(0).toUpperCase() + texto.slice(1);
  }



  formatPrecio(price: number | undefined): string | undefined {
    if (price === undefined || price === null) {
      return undefined;
    }
    return price.toLocaleString();
  }

  openModalPlanFree(template: TemplateRef<any>, plan: string, canal: string) {
    if (this.authService.instance.getAllAccounts().length > 0) {
      const currentAccount = this.authService.instance.getActiveAccount();
      if (confirm(`Actualmente iniciaste sesión como ${currentAccount.idTokenClaims['given_name']} ${currentAccount.idTokenClaims['family_name']}. A continuación vamos a cerrar tu sesión.`)) {
        this.modalPlans = this.modalService.show(template, {
          class: 'modal-dialog-centered modal-xl',
          backdrop: 'static',
          keyboard: true
        });
        this.authService.logoutPopup({
          account: currentAccount,
          popupWindowAttributes: {
            popupSize: {
              height: 100,
              width: 100
            },
            popupPosition: {
              top: 1,
              left: 1
            }
          }
        }).toPromise().then(() => {
          localStorage.removeItem('token');
          this.planSelected = plan;
          this.canalSelected = canal;

          this.planSelectedFormatted = this.formatSelectedPlan(plan);
        }).catch((error) => {
          // Manejar cualquier error que ocurra durante el deslogueo
          console.error('Error al desloguear:', error);
        });
      }
    } else {
      // Si el usuario no está autenticado, abrir el modal directamente
      this.planSelected = plan;
      this.canalSelected = canal;

      this.planSelectedFormatted = this.formatSelectedPlan(plan);

      this.modalPlans = this.modalService.show(template, {
        class: 'modal-dialog-centered modal-xl',
        backdrop: 'static',
        keyboard: true
      });
    }
  }


  openModalPlans(template: TemplateRef<any>, plan: string, canal: string, frecuencia: string) {
    if (this.authService.instance.getAllAccounts().length > 0) {
      const currentAccount = this.authService.instance.getActiveAccount();
      if (confirm(`Actualmente iniciaste sesión como ${currentAccount.idTokenClaims['given_name']} ${currentAccount.idTokenClaims['family_name']}. A continuación vamos a cerrar tu sesión.`)) {
        this.modalPlans = this.modalService.show(template, {
          class: 'modal-dialog-centered modal-xl',
          backdrop: 'static',
          keyboard: true
        });
        this.authService.logoutPopup({
          account: currentAccount,
          popupWindowAttributes: {
            popupSize: {
              height: 100,
              width: 100
            },
            popupPosition: {
              top: 1,
              left: 1
            }
          }
        }).toPromise().then(() => {
          localStorage.removeItem('token');
          this.planSelected = plan;
          this.canalSelected = canal;
          this.frecuenciaSelected = frecuencia;

          this.planSelectedFormatted = this.formatSelectedPlan(plan);
        }).catch((error) => {
          console.error('Error al desloguear:', error);
        });
      }
    } else {
      this.planSelected = plan;
      this.canalSelected = canal;
      this.frecuenciaSelected = frecuencia;

      this.planSelectedFormatted = this.formatSelectedPlan(plan);

      this.modalPlans = this.modalService.show(template, {
        class: 'modal-dialog-centered modal-xl',
        backdrop: 'static',
        keyboard: true
      });
    }

  }

  openModalPlanAnual(template: TemplateRef<any>, plan: string, canal: string, frecuencia: string) {
    if (this.authService.instance.getAllAccounts().length > 0) {
      const currentAccount = this.authService.instance.getActiveAccount();
      if (confirm(`Actualmente iniciaste sesión como ${currentAccount.idTokenClaims['given_name']} ${currentAccount.idTokenClaims['family_name']}. A continuación vamos a cerrar tu sesión.`)) {
        this.modalPlanAnual = this.modalService.show(template, {
          class: 'modal-dialog-centered modal-xl',
          backdrop: 'static',
          keyboard: true
        });
        this.authService.logoutPopup({
          account: currentAccount,
          popupWindowAttributes: {
            popupSize: {
              height: 100,
              width: 100
            },
            popupPosition: {
              top: 1,
              left: 1
            }
          }
        }).toPromise().then(() => {
          localStorage.removeItem('token');
          this.planSelectedAnual = plan;
          this.canalSelectedAnual = canal;
          this.frecuenciaSelectedAnual = frecuencia;

          this.planSelectedFormatted = this.formatSelectedPlan(plan);

        }).catch((error) => {
          console.error('Error al desloguear:', error);
        });
      }
    } else {
      this.planSelectedAnual = plan;
      this.canalSelectedAnual = canal;
      this.frecuenciaSelectedAnual = frecuencia;

      this.planSelectedFormatted = this.formatSelectedPlan(plan);

      this.modalPlanAnual = this.modalService.show(template, {
        class: 'modal-dialog-centered modal-xl',
        backdrop: 'static',
        keyboard: true
      });
    }
  }

  formatSelectedPlan(plan: string): string {
    switch (plan) {
      case 'prueba-gratis':
        return this.lang === 'en' ? 'Free Trial' : (this.lang === 'nl' ? 'Gratis Proefperiode' : 'Prueba Gratis');
      case 'basico':
        return this.lang === 'en' ? 'Basic plan' : (this.lang === 'nl' ? 'Basis' : 'plan Básico');
      case 'estandar':
        return this.lang === 'en' ? 'Standard plan' : (this.lang === 'nl' ? 'Standaard' : 'plan Estándar');
      case 'premium':
        return this.lang === 'en' ? 'Premium plan' : (this.lang === 'nl' ? 'Premium' : 'plan Premium');
      default:
        return '';
    }
  }

  ngOnDestroy(): void {
    AOS.refresh();
    this.subscriptions$.forEach(subscription => subscription.unsubscribe());
  }
}

<div class="modal-body">
  <div class="register-container">
    <div class="row m-0 row-container">
      <div class="col d-block m-auto">
        <div class="w-100 bg-nati-avatar">
          <img src="../../../assets/images/nati-new-logo.png" class="position-absolute top-0 m-3" alt="Nati"
            width="155" height="65">

          <div class="text-center">
            <img src="../../../assets/images/landing/nati.png" alt="Nati avatar" class="img-fluid avatar"
              style="margin-top: 12%;">
          </div>

          <div class="blur-container">
            <div class="blur-text">
              <h5>{{'contenidoMain.modales.textImg1' | translate}}  <br><b>{{'contenidoMain.modales.textImg2' | translate}}</b></h5>
              <p class="mt-3">{{'contenidoMain.modales.textImgSubtitulo' | translate}}</p>
            </div>
          </div>
        </div>
      </div>

      <div class="col">
        <div class="w-75 d-block m-auto">
          <div class="container-col-left">
            <div>
              <h4 class="text-center fw-bold">{{'contenidoDashboard.facturacion.metododepago.titulo' | translate}}</h4>
              <hr class="line-unavailable">
            </div>

            <div>
              <h5>{{'contenidoDashboard.facturacion.metododepago.subtitulo' | translate}}</h5>
              <div class="row">
                <div class="col-4 mb-2">
                  <img src="../../../../../assets/images/dashboard/facturacion/visa.png" alt="" style="width: 30%;">
                </div>
                <div class="col-4 mb-2">
                  <img src="../../../../../assets/images/dashboard/facturacion/american-express.png" alt=""
                    style="width: 30%;margin-left: -60%;">
                </div>
                <div class="col-4 mb-2">
                  <img src="../../../../../assets/images/dashboard/facturacion/mastercard.png" alt=""
                    style="width: 30%;margin-left: -122%;margin-top: 5%;">
                </div>
              </div>
            </div>

            <div class="container-form">
              <form [formGroup]="paymentForm" id="payment-form-modal">
                <div class="payment-form">
                  <label for="card-holder"><b>{{'contenidoDashboard.facturacion.metododepago.formulario.campo1' |
                      translate}}</b></label>
                  <input type="text" id="card-holder" class="form-control"
                    placeholder="{{'contenidoDashboard.configuracionInicial.placeholder' | translate}}"
                    formControlName="cardHolder" required>
                  <div class="text-danger" style="font-size: 15px;"
                    *ngIf="paymentForm.get('cardHolder').invalid && (paymentForm.get('cardHolder').dirty || paymentForm.get('cardHolder').touched)">
                    <ng-container *ngIf="paymentForm.get('cardHolder').errors['pattern']">
                      {{'contenidoDashboard.facturacion.metododepago.alertas.noVacio1' | translate}}
                    </ng-container>
                    <ng-container *ngIf="paymentForm.get('cardHolder').errors['minlength']">
                      {{'contenidoDashboard.facturacion.metododepago.alertas.minimo' | translate}} 5
                      {{'contenidoDashboard.facturacion.metododepago.alertas.caracteres' | translate}}
                    </ng-container>
                    <ng-container *ngIf="paymentForm.get('cardHolder').errors['maxlength']">
                      {{'contenidoDashboard.facturacion.metododepago.alertas.maximoCaracteres1' | translate}} 20
                      {{'contenidoDashboard.facturacion.metododepago.alertas.caracteres' | translate}}
                    </ng-container>
                    <ng-container *ngIf="paymentForm.get('cardHolder').hasError('required')">
                      {{'contenidoDashboard.facturacion.metododepago.alertas.campoObligatorio' | translate}}
                    </ng-container>
                  </div>
                </div>
                <div class="payment-form">
                  <label for="card-number"><b>{{'contenidoDashboard.facturacion.metododepago.formulario.campo2' |
                      translate}}</b></label>
                  <input type="text" id="card-number" class="form-control" placeholder="1234 5678 9012 3456"
                    formControlName="cardNumber" #cardNumberInput required pattern="[0-9]*">
                  <div class="text-danger" style="font-size: 15px;"
                    *ngIf="paymentForm.get('cardNumber').invalid && (paymentForm.get('cardNumber').dirty || paymentForm.get('cardNumber').touched)">
                    <ng-container *ngIf="paymentForm.get('cardNumber').errors['pattern']">
                      {{'contenidoDashboard.facturacion.metododepago.alertas.noVacio2' | translate}}
                    </ng-container>
                    <ng-container *ngIf="paymentForm.get('cardNumber').errors['minlength']">
                      {{'contenidoDashboard.facturacion.metododepago.alertas.minimo' | translate}} 10
                      {{'contenidoDashboard.facturacion.metododepago.alertas.caracteres' | translate}}
                    </ng-container>
                    <ng-container *ngIf="paymentForm.get('cardNumber').errors['maxlength']">
                      {{'contenidoDashboard.facturacion.metododepago.alertas.maximoCaracteres1' | translate}} 20
                      {{'contenidoDashboard.facturacion.metododepago.alertas.caracteres' | translate}}
                    </ng-container>
                    <ng-container *ngIf="paymentForm.get('cardNumber').hasError('required')">
                      {{'contenidoDashboard.facturacion.metododepago.alertas.campoObligatorio' | translate}}
                    </ng-container>
                  </div>
                </div>

                <div class="row">
                  <div class="col-6">
                    <div class="payment-form">
                      <label for="cvc"><b>{{'contenidoDashboard.facturacion.metododepago.formulario.campo3' |
                          translate}}</b></label>
                      <input type="text" id="cvc" class="form-control" placeholder="123" formControlName="cvc" #cvcInput
                        required pattern="[0-9]*" maxlength="4">
                      <div class="text-danger" style="font-size: 15px;"
                        *ngIf="paymentForm.get('cvc').invalid && (paymentForm.get('cvc').dirty || paymentForm.get('cvc').touched)">
                        <ng-container *ngIf="paymentForm.get('cvc').errors['minlength']">
                          {{'contenidoDashboard.facturacion.metododepago.alertas.minimo' | translate}} 3
                          {{'contenidoDashboard.facturacion.metododepago.alertas.caracteres' | translate}}
                        </ng-container>
                        <ng-container *ngIf="paymentForm.get('cvc').errors['maxlength']">
                          {{'contenidoDashboard.facturacion.metododepago.alertas.maximoCaracteres1' | translate}} 4
                          {{'contenidoDashboard.facturacion.metododepago.alertas.caracteres' | translate}}
                        </ng-container>
                        <ng-container *ngIf="paymentForm.get('cvc').hasError('required')">
                          {{'contenidoDashboard.facturacion.metododepago.alertas.campoObligatorio' | translate}}
                        </ng-container>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <label for="expiry"><b>{{'contenidoDashboard.facturacion.metododepago.formulario.campo4' |
                        translate}}</b></label>
                    <div class="expiry-inputs">
                      <p-dropdown class="form-contorl" [options]="months" [(ngModel)]="selectedMonth"
                        placeholder="{{'contenidoDashboard.facturacion.metododepago.placeholderMes' | translate}}"
                        id="expiry-month" optionLabel="value" formControlName="expiryMonth"></p-dropdown>
                      <p-dropdown [options]="years" [(ngModel)]="selectedYear"
                        placeholder="{{'contenidoDashboard.facturacion.metododepago.placeholderAño' | translate}}"
                        id="expiry-year" optionLabel="label" formControlName="expiryYear"></p-dropdown>
                    </div>
                  </div>
                </div>
                <div class="d-flex gap-2">
                  <p-checkbox [(ngModel)]="acceptedTerms" [binary]="true" formControlName="acceptedTerms"
                    id="tyc"></p-checkbox>
                  <label for="tyc"
                    (click)="toggleCheckbox()">{{'contenidoDashboard.facturacion.metododepago.textoTerminos' |
                    translate}}</label>
                </div>

                <br>
                <hr class="line-unavailable" style="margin-top: 0rem !important;">
                <div class="d-flex justify-content-end" style="margin-right: 10%;">
                  <button class="button-primary-dashboard" [disabled]="!paymentForm.valid || isLoading" (click)="onSubmit()">
                    {{ isLoading ? 'Guardando...' : 'Guardar' }}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
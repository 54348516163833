import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { TenantInfo } from 'src/app/models/dashboard/dashboard.model';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import { ClienteService } from 'src/app/services/facturacion/cliente.service';
import { Clientes } from 'src/app/models/facturacion/cliente.model';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ObtenerPlanesService } from 'src/app/services/facturacion/obtener-planes.service';
import { Planes } from 'src/app/models/facturacion/obtener-planes.model';
import { ModalAgregarMetodopagoComponent } from '../modal-agregar-metodopago/modal-agregar-metodopago.component';
import { CiudadService } from 'src/app/services/facturacion/ciudad.service';
import { Ciudad } from 'src/app/models/facturacion/ciudad.model'
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-modal-informacion-cliente',
  templateUrl: './modal-informacion-cliente.component.html',
  styleUrls: ['./modal-informacion-cliente.component.scss']
})

export class ModalInformacionClienteComponent implements OnInit {
  informacionTributaria: FormGroup;
  @Input() planSelected: string;
  @ViewChild('cityDropdown') cityDropdown: ElementRef;
  planes: Planes;
  planSelectedID: string = '';

  acceptedTerms: boolean = false;
  tipoPersona: any[];
  documentos: any[];
  codigo: any[];
  ciudades: Ciudad[] = [];
  selectedCity: any;
  // tipoPersonaSelected: any;
  // codigoSelected: any
  // documentoSelected: any
  ciudadSelected: string = '';

  tenantInfo$: Subscription;
  dataService: any;
  tenant: string;
  nombre: any[];
  apellido: string;
  correoTenant: string;
  telefono: string;

  nombresCiudades: any[];
  showDropdown = false;

  countryCode: string;
  stateCode: string;
  cityCode: string;
  cityName: string;

  loading: boolean = false;

  constructor(private fb: FormBuilder,
    private clienteService: ClienteService,
    private dashboardService: DashboardService,
    private modalRef: BsModalRef,
    private obtenerPlanesService: ObtenerPlanesService,
    private modalService: BsModalService,
    private ciudadService: CiudadService,
    private translate: TranslateService) {

    this.informacionTributaria = this.fb.group({
      tipoPersona: ['', Validators.required],
      tipoDocumento: ['', [Validators.required]],
      direccion: ['', [Validators.required, Validators.maxLength(50), Validators.minLength(5), Validators.pattern(/^\S/)]],
      nombreContacto: ['', [Validators.required, Validators.maxLength(20), Validators.minLength(4), Validators.pattern(/^[\p{L}\s'.-]+$/u), Validators.pattern(/[^\s]+/)]],
      correoFactura: ['', [Validators.required, Validators.email,Validators.pattern(/^[^\s@]+@[^\s@]+\.[^\s@]{1,4}$/)]],
      codigoFiscal: ['', Validators.required],
      numeroIdentificacion: ['', [Validators.required, Validators.maxLength(20),Validators.minLength(3), Validators.pattern('^[0-9]*$')]],
      ciudad: ['', Validators.required],
      apellidoContacto: ['', [Validators.required, Validators.maxLength(20), Validators.minLength(4), Validators.pattern(/^[\p{L}\s'.-]+$/u), Validators.pattern(/[^\s]+/)]],
      telefonoFactura: ['', [Validators.required, Validators.maxLength(10), Validators.minLength(7), Validators.pattern('^[0-9]*$')]],
      iva: [false]
    });
  }

  ngOnInit() {
    this.clienteService.getTipoPersonaOptions().subscribe((data) => {
      this.tipoPersona = data;
    });
    this.clienteService.getCodigoResponsabilidadOptions().subscribe((data) => {
      this.codigo = data;
    });
    this.clienteService.getDocumentoOptions().subscribe((data) => {
      this.documentos = data;
    });

    this.obtenerPlanesService.obtenerPlanes().subscribe({
      next: (data: Planes) => {
        this.planes = data;
        console.log('Planes:', this.planes);

        this.planSelectedID = this.planes.data.find((plan) => plan.titulo === this.planSelected).idPlan;
      }
    });
  }

  @HostListener('document:click', ['$event'])
  clickOutside(event: Event): void {
    if (this.cityDropdown && !this.cityDropdown.nativeElement.contains(event.target)) {
      // Si el clic no ocurrió dentro del dropdown, ocúltalo
      this.showDropdown = false;
    }
  }

  // onCiudadInputChange(event: Event) {
  //   const ciudadInput = event.target as HTMLInputElement;
  //   const ciudad = ciudadInput.value;

  //   if (ciudad.length >= 3) {
  //     this.ciudadService.searchCiudades(ciudad).subscribe(
  //       (ciudades: any) => {
  //         this.ciudades = ciudades.data;
  //         this.showDropdown = true;
  //       },
  //       (error) => {
  //         console.error('Error al buscar ciudades:', error);
  //         this.showDropdown = false;
  //       }
  //     );
  //   } else {
  //     this.showDropdown = false;
  //   }
  // }
  onCiudadInputChange(event: any) {
    const ciudad = event.filter || '';

    if (ciudad.length >= 3) {
      this.ciudadService.searchCiudades(ciudad).subscribe({
        next: (ciudades: any) => {
          this.ciudades = ciudades.data;
        },
        error: (error) => {
          console.error('Error al buscar ciudades:', error);
        }
      });
    }
  }

  selectCity(selectedCity: Ciudad) {
    //this.informacionTributaria.get('ciudadSelected').value
    this.ciudadSelected = selectedCity.cityName;
    console.log(selectedCity)
    this.showDropdown = false;
    this.countryCode = selectedCity.countryCode;
    this.stateCode = selectedCity.stateCode;
    this.cityCode = selectedCity.cityCode;
    this.cityName = selectedCity.cityName;

    this.informacionTributaria.patchValue({
      ciudad: {
        cityCode: selectedCity.cityCode,
        cityName: selectedCity.cityName,
        countryCode: selectedCity.countryCode,
        countryName: selectedCity.countryName,
        stateCode: selectedCity.stateCode,
        stateName: selectedCity.stateName,
      }
    });

    this.selectedCity = this.informacionTributaria.value.ciudad;
  }

  toggleCheckbox() {
    this.acceptedTerms = !this.informacionTributaria.get('iva').value
  }

  onSubmit() {
    this.tenantInfo$ = this.dashboardService.getTenantInfo$.subscribe({
      next: (data: TenantInfo) => {
        if (data.haveTenant) {
          this.dataService = data;
          this.tenant = this.dataService.tenantName;
          this.nombre = this.dataService.dataB2C.given_name;
          this.apellido = this.dataService.dataB2C.family_name;
          this.telefono = this.dataService.dataB2C.extension_Celular;
          this.correoTenant = this.dataService.dataB2C.emails[0];
          this.guardarInfoTributaria()
        } else if (!data.haveTenant) {
          return;
        }
      }, error: (error) => {
        console.log(error);
      }
    });
  }

  guardarInfoTributaria() {
    console.log("Entra a el metodo guardarInfoTributaria")
    if (this.informacionTributaria.valid) {
      console.log("El formulario es valido")
      let name: any[];
      if (this.informacionTributaria.get('tipoPersona').value.value == 'Person') {
        name = [this.nombre, this.apellido]
      } else {
        name = [this.tenant]
      }

      const formData: Clientes = {
        tenant: this.tenant,
        personType: this.informacionTributaria.get('tipoPersona').value.value,
        idType: this.informacionTributaria.get('tipoDocumento').value.value,
        identification: this.informacionTributaria.get('numeroIdentificacion').value,
        name: name,
        vatResponsible: this.informacionTributaria.get('iva').value,
        fiscalResponsabilitys: {
          code: this.informacionTributaria.get('codigoFiscal').value.value,
        },
        address: {
          address: this.informacionTributaria.get('direccion').value,
          city: {
            countryCode: this.countryCode,
            stateCode: this.stateCode,
            cityCode: this.cityCode,
            cityName: this.cityName,
          },
        },
        telefonoTenant: this.telefono,
        correoTenant: this.correoTenant,
        contactoFacturacion: [
          {
            nombre: this.informacionTributaria.get('nombreContacto').value,
            apellido: this.informacionTributaria.get('apellidoContacto').value,
            correo: this.informacionTributaria.get('correoFactura').value,
            telefono: this.informacionTributaria.get('telefonoFactura').value,
          }
          // Agrega más objetos de contacto si es necesario
        ]
      };
      this.loading = true;
      this.clienteService.crearClienteCompleto(formData).subscribe(
        () => {
          this.continueWithPlan()
          this.translate.get('contenidoDashboard.facturacion.informacionTributaria.formulario.mensajeExitoTitulo').subscribe((translatedTitle: string) => {
            this.translate.get('contenidoDashboard.facturacion.informacionTributaria.formulario.mensajeExitoTexto').subscribe((translatedText: string) => {
              Swal.fire({
                customClass: {
                  confirmButton: 'swalConfirmBtn',
                  cancelButton: 'swalCancelBtn'
                },
                title: translatedTitle,
                text: translatedText,
                icon: 'success',
                iconColor: '#0E2359',
                confirmButtonText: 'Aceptar'
              });
            });
          });

          this.loading = false;
        },
        (error) => {
          if (error.error.statusCode == 400) {
            if ( error.error.message.includes("SIIGO: Invalid email")) {
              this.translate.get('contenidoDashboard.facturacion.informacionTributaria.formulario.alertas.titleError').subscribe((translatedTitle: string) => {
                this.translate.get('contenidoDashboard.facturacion.informacionTributaria.formulario.alertas.correoValido').subscribe((translatedText: string) => {
                  Swal.fire({
                    customClass: {
                      confirmButton: 'swalConfirmBtn',
                      cancelButton: 'swalCancelBtn'
                    },
                    title: translatedTitle,
                    text: translatedText,
                    icon: 'error',
                    confirmButtonText: "Aceptar",
                  });
                });
              });
            } else if (error.error.message.includes("SIIGO: The identification already exists")){
              this.translate.get('contenidoDashboard.facturacion.informacionTributaria.formulario.alertas.titleError').subscribe((translatedTitle: string) => {
                this.translate.get('contenidoDashboard.facturacion.informacionTributaria.formulario.alertas.identificacionValida').subscribe((translatedText: string) => {
                  Swal.fire({
                    customClass: {
                      confirmButton: 'swalConfirmBtn',
                      cancelButton: 'swalCancelBtn'
                    },
                    title: translatedTitle,
                    text: translatedText,
                    icon: 'error',
                    confirmButtonText: "Aceptar",
                  });
                });
              });

            }  else {
              let errorMessage = 'Ocurrió un error desconocido. Contacte el administrador';
              if (error.error && error.error.message) {
                errorMessage = error.error.message;
              }
              Swal.fire({
                customClass: {
                  confirmButton: 'swalConfirmBtn',
                  cancelButton: 'swalCancelBtn'
                },
                title: 'Error',
                text: errorMessage,
                icon: 'error',
                confirmButtonText: 'Aceptar',
              });
            }
          }
          this.loading = false;
          console.error('Error al crear el cliente:', error);
        }
      );
      console.log(formData);
    } else {
      console.log("El formulario no es valido")
      Swal.fire({
        customClass: {
          confirmButton: 'swalConfirmBtn',
          cancelButton: 'swalCancelBtn'
        },
        title: 'Error',
        text: 'Se ha producido un problema al guardar la información. Por favor, complete el formulario correctamente',
        icon: 'error',
        confirmButtonText: 'Aceptar',
      });
    }
  }

  continueWithPlan() {
    this.modalRef.hide();

    setTimeout(() => {
      this.openModalAddPayMethod();
    }, 500);
  }

  openModalAddPayMethod() {
    const initialState = {
      planSelected: this.planSelected
    };

    this.modalRef = this.modalService.show(ModalAgregarMetodopagoComponent, {
      initialState,
      class: 'modal-dialog-centered modal-xl',
      backdrop: 'static',
      keyboard: false
    });
  }
}

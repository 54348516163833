import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ModalAgregarMetodopagoComponent } from '../modal-agregar-metodopago/modal-agregar-metodopago.component';
import { ModalInformacionClienteComponent } from '../modal-informacion-cliente/modal-informacion-cliente.component';
import { ClienteService } from 'src/app/services/facturacion/cliente.service';
import { Subscription } from 'rxjs';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import { TenantInfo } from 'src/app/models/dashboard/dashboard.model';
import Swal from 'sweetalert2';

import { ObtenerPlanesService } from 'src/app/services/facturacion/obtener-planes.service';
import { Planes, Datum } from 'src/app/models/facturacion/obtener-planes.model';

@Component({
  selector: 'app-modal-seleccionar-plan',
  templateUrl: './modal-seleccionar-plan.component.html',
  styleUrls: ['./modal-seleccionar-plan.component.scss']
})
export class ModalSeleccionarPlanComponent implements OnInit {
  tenantInfo$: Subscription;
  dataService: any;
  tenant: string = '';

  planes: Planes | undefined;
  planesCanalWebMensual: Datum[] = [];
  planesCanalKioscoMensual: Datum[] = [];
  planesCanalWhatsappMensual: Datum[] = [];
  planesCanalWebAnual: Datum[] = [];
  planesCanalKioscoAnual: Datum[] = [];
  planesCanalWhatsappAnual: Datum[] = [];

  showSectionWeb = true;
  showSectionKiosco = false;
  showSectionWhatsapp = false;

  constructor(private modalRef: BsModalRef, private modalService: BsModalService, private clienteService: ClienteService, private dashboardService: DashboardService, private obtenerPlanesService: ObtenerPlanesService) {

  }

  ngOnInit() {
    this.tenantInfo$ = this.dashboardService.getTenantInfo$.subscribe({
      next: (data: TenantInfo) => {
        if (data.haveTenant) {
          this.dataService = data;
          this.tenant = this.dataService.tenantName;

          this.obtenerPlanes();
        } else if (!data.haveTenant) {
          return;
        }
      }, error: (error) => {
        console.log(error);
      }
    });
  }

  obtenerPlanes(): void {
    this.obtenerPlanesService.obtenerPlanes().subscribe({
      next: (planes: Planes) => {
        this.planes = planes;
        const planesDisponibles = ['basico', 'estandar', 'premium'];

        const procesarPlanes = (canal, frecuencia) => {
          return planes.data
            .filter(plan => plan.canal === canal && plan.tipoFrecuencia === frecuencia)
            .filter(plan => planesDisponibles.includes(plan.titulo))
            .sort((a, b) => planesDisponibles.indexOf(a.titulo) - planesDisponibles.indexOf(b.titulo))
            .map(plan => ({
              ...plan,
              titulo: this.convertirPrimeraLetraMayuscula(plan.titulo)
            }));
        };

        this.planesCanalWebMensual = procesarPlanes('web', 'mensual');
        this.planesCanalWebAnual = procesarPlanes('web', 'anual');
        this.planesCanalKioscoMensual = procesarPlanes('kiosco', 'mensual');
        this.planesCanalKioscoAnual = procesarPlanes('kiosco', 'anual');
        this.planesCanalWhatsappMensual = procesarPlanes('whatsapp', 'mensual');
        this.planesCanalWhatsappAnual = procesarPlanes('whatsapp', 'anual');
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  convertirPrimeraLetraMayuscula(texto: string): string {
    texto = texto.replace('basico', 'Básico').replace('estandar', 'Estándar');
    return texto.charAt(0).toUpperCase() + texto.slice(1);
  }

  formatPrecio(price: number | undefined): string | undefined {
    if (price === undefined || price === null) {
      return undefined;
    }
    return price.toLocaleString();
  }

  showSection(section: string) {
    switch (section) {
      case 'web':
        this.showSectionWeb = true;
        this.showSectionKiosco = false;
        this.showSectionWhatsapp = false;
        break;
      case 'kiosco':
        this.showSectionWeb = false;
        this.showSectionKiosco = true;
        this.showSectionWhatsapp = false;
        break;
      case 'whatsapp':
        this.showSectionWeb = false;
        this.showSectionKiosco = false;
        this.showSectionWhatsapp = true;
        break;
    }
  }

  continueWithPlan(idPlan: string) {
    this.clienteService.obtenerCliente(this.tenant).subscribe({
      next: (clientes: any) => {
        if (clientes.data.idCustomer) {
          this.modalRef.hide();

          setTimeout(() => {
            this.openModalAddPayMethod(idPlan);
          }, 500);
        } else {
          this.modalRef.hide();

          setTimeout(() => {
            this.openModalInfoTributaria(idPlan);
          }, 500);
        }
      }, error: (error) => {
        console.log(error);

        if (error.status >= 400 && error.status < 500) {
          this.modalRef.hide();

          setTimeout(() => {
            this.openModalInfoTributaria(idPlan);
          }, 500);
          console.error('Error del cliente:', error);
        } else if (error.status >= 500 && error.status < 600) {
          Swal.fire({
            customClass: {
              confirmButton: 'swalConfirmBtn',
              cancelButton: 'swalCancelBtn'
            },
            title: error.error.message,
            text: error.error.details,
            icon: 'error',
            confirmButtonText: 'Aceptar'
          });
        } else {
          console.error('Error desconocido:', error);
        }
      }
    });
  }

  openModalInfoTributaria(idPlan: string) {
    const initialState = {
      planSelected: idPlan
    };

    this.modalRef = this.modalService.show(ModalInformacionClienteComponent, {
      initialState,
      class: 'modal-dialog-centered modal-xl',
      backdrop: 'static',
      keyboard: false
    });
  }

  openModalAddPayMethod(idPlan: string) {
    const initialState = {
      planSelected: idPlan
    };

    this.modalRef = this.modalService.show(ModalAgregarMetodopagoComponent, {
      initialState,
      class: 'modal-dialog-centered modal-xl',
      backdrop: 'static',
      keyboard: false
    });
  }

}

<div class="container-fluid footer">
  <div class="d-flex gap-3 flex-wrap justify-content-center align-items-center">
    <div class="d-block d-md-none">
      <app-switchlang></app-switchlang>
    </div>

    <div class="social-link">
      <a *ngIf="language === 'es'" href="https://www.instagram.com/natipersonadigital/" target="_blank">
        <svg xmlns="http://www.w3.org/2000/svg" class="redes" viewBox="0 0 24 24"><path d="M20.947 8.305a6.53 6.53 0 0 0-.419-2.216 4.61 4.61 0 0 0-2.633-2.633 6.606 6.606 0 0 0-2.186-.42c-.962-.043-1.267-.055-3.709-.055s-2.755 0-3.71.055a6.606 6.606 0 0 0-2.185.42 4.607 4.607 0 0 0-2.633 2.633 6.554 6.554 0 0 0-.419 2.185c-.043.963-.056 1.268-.056 3.71s0 2.754.056 3.71c.015.748.156 1.486.419 2.187a4.61 4.61 0 0 0 2.634 2.632 6.584 6.584 0 0 0 2.185.45c.963.043 1.268.056 3.71.056s2.755 0 3.71-.056a6.59 6.59 0 0 0 2.186-.419 4.615 4.615 0 0 0 2.633-2.633c.263-.7.404-1.438.419-2.187.043-.962.056-1.267.056-3.71-.002-2.442-.002-2.752-.058-3.709zm-8.953 8.297c-2.554 0-4.623-2.069-4.623-4.623s2.069-4.623 4.623-4.623a4.623 4.623 0 0 1 0 9.246zm4.807-8.339a1.077 1.077 0 0 1-1.078-1.078 1.077 1.077 0 1 1 2.155 0c0 .596-.482 1.078-1.077 1.078z"/><circle cx="11.994" cy="11.979" r="3.003"/></svg>
      </a>
    </div>

    <div class="social-link">
      <a href="{{'contenidoMain.footer.linkedin' | translate}}" target="_blank">
        <svg xmlns="http://www.w3.org/2000/svg" class="redes" viewBox="0 0 24 24"><path d="M20 3H4a1 1 0 0 0-1 1v16a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1zM8.339 18.337H5.667v-8.59h2.672v8.59zM7.003 8.574a1.548 1.548 0 1 1 0-3.096 1.548 1.548 0 0 1 0 3.096zm11.335 9.763h-2.669V14.16c0-.996-.018-2.277-1.388-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248h-2.667v-8.59h2.56v1.174h.037c.355-.675 1.227-1.387 2.524-1.387 2.704 0 3.203 1.778 3.203 4.092v4.71z"/></svg>
      </a>
    </div>

    <a class="linkF" (click)="openModalTerminos()">{{'contenidoMain.footer.terminos' | translate}}</a>

    <a class="linkF" (click)="openModalPoliticas()">{{'contenidoMain.footer.politicas' | translate}}</a>

    <span class="opacity-50">© {{currentYear}}  Innovati Software</span>
  </div>
</div>
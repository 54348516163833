import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CrearSuscripcion } from 'src/app/models/facturacion/crear-suscripcion.model';

@Injectable({
  providedIn: 'root'
})
export class CrearSuscripcionService {

  private urlCrearSuscripcion: string = environment.urlCrearSuscripcion;

  constructor(private http: HttpClient) { }

  crearSuscripcion(suscripcion: CrearSuscripcion): Observable<CrearSuscripcion> {
    return this.http.post<CrearSuscripcion>(this.urlCrearSuscripcion, suscripcion);
  }
}

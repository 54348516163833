import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-terminos-uso',
  templateUrl: './terminos-uso.component.html',
  styleUrls: ['./terminos-uso.component.scss']
})
export class TerminosUsoComponent {
  constructor(private modalRef: BsModalRef) {

  }

  closeModal() {
    this.modalRef.hide();
  }

}

<div class="switchlang">
    <p-dropdown [options]="languages" [(ngModel)]="selectedLanguage" optionLabel="name" (onChange)="switchLang()">
        <ng-template let-country pTemplate="selectedItem">
            <div class="d-flex justify-content-start gap-2" *ngIf="selectedLanguage">
                <img src="../../../assets/images/language/{{country.code}}.png" class="flag" alt="{{country.code}}">
                <div>{{selectedLanguage.name.toLocaleLowerCase()}}</div>
            </div>
        </ng-template>
        <ng-template let-country pTemplate="item">
            <div class="d-flex justify-content-start gap-2">
                <img src="../../../assets/images/language/{{country.code}}.png" class="flag" alt="{{country.code}}">
                <div>{{country.name.toLocaleLowerCase()}}</div>
            </div>
        </ng-template>
    </p-dropdown>
</div>

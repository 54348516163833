import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NavbarComponent } from './navbar/navbar.component';
import { SwitchlangComponent } from './switchlang/switchlang.component';
import { FooterComponent } from './footer/footer.component';
import { PoliticasComponent } from './politicas/politicas.component';

import { TranslateModule } from '@ngx-translate/core';

//PrimeNG
import { DropdownModule } from 'primeng/dropdown';
import { CheckboxModule } from 'primeng/checkbox';

//NGX-COLOR-PICKER
import { ColorPickerModule } from 'ngx-color-picker';
import { NotificacionesComponent } from './notificaciones/notificaciones.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { TerminosUsoComponent } from './terminos-uso/terminos-uso.component';
import { PoliticasPrivacidadComponent } from './politicas-privacidad/politicas-privacidad.component';

@NgModule({
  declarations: [
    NavbarComponent,
    SwitchlangComponent,
    FooterComponent,
    PoliticasComponent,
    NotificacionesComponent,
    TerminosUsoComponent,
    PoliticasPrivacidadComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    TranslateModule,

    //PrimeNG
    DropdownModule,
    CheckboxModule,

    //NGX-COLOR-PICKER
    ColorPickerModule,

    //Angular Material
    MatMenuModule,
    MatIconModule
  ],
  exports: [
    NavbarComponent,
    SwitchlangComponent,
    FooterComponent,
    TranslateModule,
    ColorPickerModule,
  ]
})
export class SharedModule { }

import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { ObtenerSuscripcionesActivasService } from 'src/app/services/facturacion/obtener-suscripciones-activas.service';
import { SuscripcionesTenant } from 'src/app/models/facturacion/obtener-suscripciones-tenant.model';
import { filter } from 'rxjs';

@Injectable()
export class SubscriptionsGuardService implements CanActivate {

  private dataB2C: any;

  constructor(
    private _router: Router,
    private _obtenerSuscripcionesActivasService: ObtenerSuscripcionesActivasService,
    private _msalBroadcastService: MsalBroadcastService,
    private _authService: MsalService,
  ) {}

  canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      const routeUrl: string = state.url;

      this._msalBroadcastService.inProgress$.pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None)
      )
      .subscribe(() => {
        const activeAccount = this._authService.instance.getActiveAccount();
        if (activeAccount) {
          this.getClaims(activeAccount.idTokenClaims);
        }

        const tenant = this.dataB2C.extension_Empresa;
        // console.log('Tenant: ', tenant);

        // console.log('Obteniendo suscripciones activas...');
        this._obtenerSuscripcionesActivasService.obtenerSuscripcionesActivas(tenant).subscribe({
          next: (data: SuscripcionesTenant) => {
            // console.log('Suscripciones activas: ', data.data);
            const hasActiveSubscription = data.data.some((suscripcion) => routeUrl.includes(suscripcion.canal));

            if (hasActiveSubscription) {
              // console.log('Puede acceder a la ruta ya que tiene la suscripción activa.');
              resolve(true);
            } else {
              // console.log('No tiene activa la suscripción necesaria para acceder a la ruta.');
              this._router.navigate(['/dashboard/suscripcion']);
              resolve(false);
            }
          },
          error: (error) => {
            console.log(error);
            this._router.navigate(['/dashboard/suscripcion']);
            resolve(false);
          }
        });
      });
    });
  }

  getClaims(claims: any) {
    this.dataB2C = claims;
  }
}

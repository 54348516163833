<div class="politicas-container">
  <div class="text-center mt-5">
    <h1>Nuestras políticas</h1>

    <ul class="list-unstyled mt-3">
      <li>
        <a href="../../../../assets/Política-de-Tratamiento-de-Datos-Personales.pdf" target="_blank">Política de Tratamiento de Datos Personales</a>
      </li>
      <li class="my-2">
        <a href="../../../../assets/Política-de-Micrófono.pdf" target="_blank">Política de Micrófono</a>
      </li>
      <li>
        <a href="../../../../assets/Política-de-Cámara.pdf" target="_blank">Política de Cámara</a>
      </li>
    </ul>
  </div>
</div>

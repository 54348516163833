<div class="modal-header">
  <button type="button" class="btn-close close pull-right" aria-label="Close" (mousedown)="closeModal()">
  </button>
</div>
<div class="modal-body">
  <div class="register-container">
    <div class="row m-0 row-container">
      <div class="col">
        <div class="w-100 d-block m-auto text-center">
          <h2>{{'contenidoMain.textoTerminos.titulo1' | translate}}</h2>

          <p>{{'contenidoMain.textoTerminos.parrafo1' | translate}}</p>

          <p>{{'contenidoMain.textoTerminos.parrafo2' | translate}}</p>
          <p>{{'contenidoMain.textoTerminos.parrafo3' | translate}}</p>

          <p>{{'contenidoMain.textoTerminos.parrafo4' | translate}}<a
              href="https://nati.ai/">https://nati.ai/</a>{{'contenidoMain.textoTerminos.parrafo5' | translate}}</p>

          <p><strong>{{'contenidoMain.textoTerminos.titulo2' | translate}}</strong></p>
          <p>{{'contenidoMain.textoTerminos.parrafo6' | translate}}</p>
          <p>{{'contenidoMain.textoTerminos.parrafo7' | translate}}</p>

          <p>{{'contenidoMain.textoTerminos.parrafo8' | translate}} <a href="https://nati.ai/">
              https://nati.ai/</a>{{'contenidoMain.textoTerminos.parrafo9' | translate}}</p>

          <p><strong>{{'contenidoMain.textoTerminos.titulo3' |
              translate}}</strong>{{'contenidoMain.textoTerminos.parrafo10' | translate}}</p>

          <p><strong>{{'contenidoMain.textoTerminos.titulo4' | translate}}</strong></p>
          <p>{{'contenidoMain.textoTerminos.parrafo11' | translate}}<a
              href="https://nati.ai/">https://nati.ai/</a>{{'contenidoMain.textoTerminos.parrafo12' | translate}}
          </p>
          <p>{{'contenidoMain.textoTerminos.parrafo13' | translate}}</p>
          <p>{{'contenidoMain.textoTerminos.parrafo14' | translate}}</p>
          <p>{{'contenidoMain.textoTerminos.parrafo15' | translate}}</p>

          <p>{{'contenidoMain.textoTerminos.parrafo16' | translate}}</p>
          <p><strong>{{'contenidoMain.textoTerminos.titulo5' | translate}}</strong></p>
          <p>{{'contenidoMain.textoTerminos.parrafo17' | translate}}</p>
          <p>{{'contenidoMain.textoTerminos.parrafo18' | translate}}</p>
          <p>{{'contenidoMain.textoTerminos.parrafo19' | translate}}</p>
          <p>{{'contenidoMain.textoTerminos.parrafoLista' | translate}}</p>
          <ol type="i">
            <li>{{'contenidoMain.textoTerminos.lista.lista1' | translate}}</li>
            <li>{{'contenidoMain.textoTerminos.lista.lista2' | translate}}</li>
            <li>{{'contenidoMain.textoTerminos.lista.lista3' | translate}}</li>
            <li>{{'contenidoMain.textoTerminos.lista.lista4' | translate}}</li>
            <li>{{'contenidoMain.textoTerminos.lista.lista5' | translate}}</li>
            <li> {{'contenidoMain.textoTerminos.lista.lista6' | translate}}</li>
          </ol>

          <p>{{'contenidoMain.textoTerminos.parrafo20' | translate}}</p>
          <p>{{'contenidoMain.textoTerminos.parrafo21' | translate}}</p>

          <p><strong>{{'contenidoMain.textoTerminos.titulo6' |
              translate}}</strong>{{'contenidoMain.textoTerminos.parrafo22' | translate}}</p>

          <p><strong>{{'contenidoMain.textoTerminos.titulo7' |
              translate}}</strong>{{'contenidoMain.textoTerminos.parrafo23' | translate}}</p>
          <h2></h2>
          <p><strong>{{'contenidoMain.textoTerminos.titulo8' |
              translate}}</strong>{{'contenidoMain.textoTerminos.parrafo24' | translate}}</p>
          <p><strong>{{'contenidoMain.textoTerminos.titulo9' | translate}}</strong></p>

          <p>{{'contenidoMain.textoTerminos.parrafo25' | translate}}</p>

          <p>{{'contenidoMain.textoTerminos.parrafo26' | translate}}</p>

          <p>{{'contenidoMain.textoTerminos.parrafo27' | translate}}</p>

          <p>{{'contenidoMain.textoTerminos.parrafo28' | translate}}</p>

          <p>{{'contenidoMain.textoTerminos.parrafo29' | translate}}</p>
        </div>
      </div>
    </div>
  </div>
</div>

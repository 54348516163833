/**
 * This file contains authentication parameters. Contents of this file
 * is roughly the same across other MSAL.js libraries. These parameters
 * are used to initialize Angular and MSAL Angular configurations in
 * in app.module.ts file.
 *
 * == Revisar link, importante
 * https://learn.microsoft.com/es-es/azure/active-directory/develop/tutorial-v2-angular-auth-code
 */

import { LogLevel, Configuration, BrowserCacheLocation } from '@azure/msal-browser';
import { environment } from 'src/environments/environment';

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

/**
 * Enter here the user flows and custom policies for your B2C application,
 * To learn more about user flows, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */
export const b2cPolicies = {
  names: {
    signUpSignIn: "B2C_1_SignupAndLogin",
    editProfile: "B2C_1_edit_profile",
    signUp: "B2C_1_SignUp"
  },
  authorities: {
    signUpSignIn: {
      authority: "https://natisaas.b2clogin.com/natisaas.onmicrosoft.com/B2C_1_SignupAndLogin",
    },
    editProfile: {
      authority: "https://natisaas.b2clogin.com/natisaas.onmicrosoft.com/B2C_1_edit_profile"
    },
    signUp: {
      authority: "https://natisaas.b2clogin.com/natisaas.onmicrosoft.com/B2C_1_SignUp"
    }
  },
  authorityDomain: "natisaas.b2clogin.com"
};

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const msalConfig: Configuration = {
  auth: {
    clientId: 'c2d47157-26fb-41da-9d54-39355f077001', // This is the ONLY mandatory field that you need to supply.
    authority: b2cPolicies.authorities.signUpSignIn.authority, // Defaults to "https://login.microsoftonline.com/common"
    knownAuthorities: [b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
    redirectUri: environment.redirectUri, // Points to window.location.origin. You must register this URI on Azure portal/App Registration.
    postLogoutRedirectUri: '/', // Indicates the page to navigate after logout.
    navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
    clientCapabilities: ["Api.Read"]
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage, // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: isIE, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback() {
      },
      logLevel: LogLevel.Verbose,
      piiLoggingEnabled: false
    }
  },
}

export const protectedResources = {
  graphMe: {
    endpoint: 'https://graph.microsoft.com/v1.0/me',
    scopes: ['Api.Read']
  }
}

/**
 * An optional silentRequest object can be used to achieve silent SSO
 * between applications by providing a "login_hint" property.
 */
export const silentRequest = {
  scopes: ["Api.Read"],
  loginHint: "example@domain.net"
};

export const loginRequest = {
  scopes: ['Api.Read'],
};

<div class="modal-header">
  <button type="button" class="btn-close close pull-right" aria-label="Close" (mousedown)="closeModal()">
  </button>
</div>
<div class="modal-body">
  <div class="register-container">
    <div class="row m-0 row-container">
      <div class="col">
        <div class="w-100 d-block m-auto text-center" style="text-align: justify;">
          <h2 style="text-align: justify;">{{'contenidoMain.politicas.titulo' | translate}}</h2>
          <p style="text-align: justify;">{{'contenidoMain.politicas.texto1' | translate}}</p>
          <p style="text-align: justify;">{{'contenidoMain.politicas.texto2' | translate}} </p>
          <p style="text-align: justify;">{{'contenidoMain.politicas.texto3' | translate}}</p>
          <p style="text-align: justify;">{{'contenidoMain.politicas.texto4' | translate}}</p>
          <p style="text-align: justify;">{{'contenidoMain.politicas.texto5' | translate}}</p>
          <p style="text-align: justify;">{{'contenidoMain.politicas.texto6' | translate}}</p>
          <ul type="i" style="text-align: justify;">
            <li>{{'contenidoMain.politicas.lista1.item1' | translate}}</li>
            <li>{{'contenidoMain.politicas.lista1.item2' | translate}}</li>
            <li>{{'contenidoMain.politicas.lista1.item3' | translate}}</li>
            <li>{{'contenidoMain.politicas.lista1.item4' | translate}}</li>
            <li>{{'contenidoMain.politicas.lista1.item5' | translate}}</li>
          </ul>
          <p style="text-align: justify;">{{'contenidoMain.politicas.texto7' | translate}}</p>
          <p style="text-align: justify;">{{'contenidoMain.politicas.texto8' | translate}}</p>
          <p style="text-align: justify;">{{'contenidoMain.politicas.texto9' | translate}}</p>
          <p style="text-align: justify;">{{'contenidoMain.politicas.texto10' | translate}}</p>
          <p style="text-align: justify;">{{'contenidoMain.politicas.texto11' | translate}}</p>
          <p style="text-align: justify;"> {{'contenidoMain.politicas.texto12' | translate}}</p>
          <p style="text-align: justify;">{{'contenidoMain.politicas.texto13' | translate}} </p>
          <p style="text-align: justify;">{{'contenidoMain.politicas.texto14' | translate}} </p>
          <p style="text-align: justify;">{{'contenidoMain.politicas.texto15' | translate}}</p>
          <ul type="i" style="text-align: justify;">
            <li>{{'contenidoMain.politicas.lista2.item1' | translate}}</li>
            <li>{{'contenidoMain.politicas.lista2.item2' | translate}}</li>
            <li>{{'contenidoMain.politicas.lista2.item3' | translate}}</li>
            <li>{{'contenidoMain.politicas.lista2.item4' | translate}}</li>
          </ul>
          <p style="text-align: justify;">{{'contenidoMain.politicas.texto16' | translate}}</p>
          <p style="text-align: justify;">{{'contenidoMain.politicas.texto17' | translate}}</p>
          <p style="text-align: justify;">{{'contenidoMain.politicas.texto18' | translate}}</p>
          <ul type="i" style="text-align: justify;">
            <li>{{'contenidoMain.politicas.lista3.item1' | translate}}</li>
            <li>{{'contenidoMain.politicas.lista3.item2' | translate}}</li>
            <li>{{'contenidoMain.politicas.lista3.item3' | translate}}</li>
            <li>{{'contenidoMain.politicas.lista3.item4' | translate}}</li>
          </ul>
          <p style="text-align: justify;">{{'contenidoMain.politicas.texto19' | translate}}</p>
          <ul type="i" style="text-align: justify;">
            <li>{{'contenidoMain.politicas.lista4.item1' | translate}}</li>
            <li>{{'contenidoMain.politicas.lista4.item2' | translate}}</li>
            <li>{{'contenidoMain.politicas.lista4.item3' | translate}}</li>
            <li>{{'contenidoMain.politicas.lista4.item4' | translate}}</li>
          </ul>
          <p style="text-align: justify;">{{'contenidoMain.politicas.texto20' | translate}}</p>
          <p style="text-align: justify;">{{'contenidoMain.politicas.texto21' | translate}}</p>
          <p style="text-align: justify;">{{'contenidoMain.politicas.texto22' | translate}}</p>
          <ul style="text-align: justify;">
            <li>{{'contenidoMain.politicas.lista5.item1' | translate}}</li>
            <li>{{'contenidoMain.politicas.lista5.item2' | translate}}</li>
            <li>{{'contenidoMain.politicas.lista5.item3' | translate}}</li>
            <li>{{'contenidoMain.politicas.lista5.item4' | translate}}</li>
            <li>{{'contenidoMain.politicas.lista5.item5' | translate}}</li>
            <li>{{'contenidoMain.politicas.lista5.item6' | translate}}</li>
            <li>{{'contenidoMain.politicas.lista5.item7' | translate}}</li>
            <li>{{'contenidoMain.politicas.lista5.item8' | translate}}</li>
          </ul>

          <p style="text-align: justify;">{{'contenidoMain.politicas.texto23' | translate}}</p>
          <p style="text-align: justify;">{{'contenidoMain.politicas.texto24' | translate}}</p>
          <ul style="text-align: justify;">
            <li>{{'contenidoMain.politicas.lista6.item1' | translate}}</li>
            <li>{{'contenidoMain.politicas.lista6.item2' | translate}}</li>
            <li>{{'contenidoMain.politicas.lista6.item3' | translate}}</li>
          </ul>
          <p style="text-align: justify;">{{'contenidoMain.politicas.texto25' | translate}}</p>
          <p style="text-align: justify;">{{'contenidoMain.politicas.texto26' | translate}}</p>
          <p style="text-align: justify;">{{'contenidoMain.politicas.texto27' | translate}}</p>
          <p style="text-align: justify;">{{'contenidoMain.politicas.texto28' | translate}}</p>
          <ul style="text-align: justify;">
            <li>{{'contenidoMain.politicas.lista7.item1' | translate}}</li>
            <li>{{'contenidoMain.politicas.lista7.item2' | translate}}</li>
            <li>{{'contenidoMain.politicas.lista7.item3' | translate}}</li>
          </ul>
          <p style="text-align: justify;">{{'contenidoMain.politicas.texto29' | translate}}</p>
          <p style="text-align: justify;">{{'contenidoMain.politicas.texto30' | translate}}</p>
          <ul style="text-align: justify;">
            <li>{{'contenidoMain.politicas.lista8.item1' | translate}}</li>
            <li>{{'contenidoMain.politicas.lista8.item2' | translate}}</li>
            <li>{{'contenidoMain.politicas.lista8.item3' | translate}}</li>
            <li>{{'contenidoMain.politicas.lista8.item4' | translate}}</li>
            <li>{{'contenidoMain.politicas.lista8.item5' | translate}}</li>
            <li>{{'contenidoMain.politicas.lista8.item6' | translate}}</li>
          </ul>
          <p style="text-align: justify;">{{'contenidoMain.politicas.texto31' | translate}}</p>
          <ul style="text-align: justify;">
            <li>{{'contenidoMain.politicas.lista9.item1' | translate}}</li>
            <li>{{'contenidoMain.politicas.lista9.item2' | translate}}</li>
            <li>{{'contenidoMain.politicas.lista9.item3' | translate}}</li>
            <li>{{'contenidoMain.politicas.lista9.item4' | translate}}</li>
            <li>{{'contenidoMain.politicas.lista9.item5' | translate}}</li>
            <li>{{'contenidoMain.politicas.lista9.item6' | translate}}</li>
            <li>{{'contenidoMain.politicas.lista9.item7' | translate}}</li>
            <li>{{'contenidoMain.politicas.lista9.item8' | translate}}</li>
            <li>{{'contenidoMain.politicas.lista9.item9' | translate}}</li>
            <li>{{'contenidoMain.politicas.lista9.item10' | translate}}</li>
            <li>{{'contenidoMain.politicas.lista9.item11' | translate}}</li>
            <li>{{'contenidoMain.politicas.lista9.item12' | translate}}</li>
            <li>{{'contenidoMain.politicas.lista9.item13' | translate}}</li>
            <li>{{'contenidoMain.politicas.lista9.item14' | translate}}</li>
          </ul>
          <p style="text-align: justify;">{{'contenidoMain.politicas.texto32' | translate}}</p>
          <ul style="text-align: justify;">
            <li>{{'contenidoMain.politicas.lista10.item1' | translate}}</li>
            <li>{{'contenidoMain.politicas.lista10.item2' | translate}}</li>
            <li>{{'contenidoMain.politicas.lista10.item3' | translate}}</li>
            <li>{{'contenidoMain.politicas.lista10.item4' | translate}}</li>
            <li>{{'contenidoMain.politicas.lista10.item5' | translate}}</li>
            <li>{{'contenidoMain.politicas.lista10.item6' | translate}}</li>
            <li>{{'contenidoMain.politicas.lista10.item7' | translate}}</li>
            <li>{{'contenidoMain.politicas.lista10.item8' | translate}}</li>
            <li>{{'contenidoMain.politicas.lista10.item9' | translate}}</li>
            <li>{{'contenidoMain.politicas.lista10.item10' | translate}}</li>
            <li>{{'contenidoMain.politicas.lista10.item11' | translate}}</li>
          </ul>
          <p style="text-align: justify;">{{'contenidoMain.politicas.texto33' | translate}}</p>
          <p style="text-align: justify;">{{'contenidoMain.politicas.texto34' | translate}}</p>
          <ul style="text-align: justify;">
            <li>{{'contenidoMain.politicas.lista11.item1' | translate}}</li>
            <li>{{'contenidoMain.politicas.lista11.item2' | translate}}</li>
            <li>{{'contenidoMain.politicas.lista11.item3' | translate}}</li>
            <li>{{'contenidoMain.politicas.lista11.item4' | translate}}</li>
            <li>{{'contenidoMain.politicas.lista11.item5' | translate}}</li>
            <li>{{'contenidoMain.politicas.lista11.item6' | translate}}</li>
            <li>{{'contenidoMain.politicas.lista11.item7' | translate}}</li>
            <li>{{'contenidoMain.politicas.lista11.item8' | translate}}</li>
            <li>{{'contenidoMain.politicas.lista11.item9' | translate}}</li>
            <li>{{'contenidoMain.politicas.lista11.item10' | translate}}</li>
            <li>{{'contenidoMain.politicas.lista11.item11' | translate}}</li>
            <li>{{'contenidoMain.politicas.lista11.item12' | translate}}</li>
            <li>{{'contenidoMain.politicas.lista11.item13' | translate}}</li>
            <li>{{'contenidoMain.politicas.lista11.item14' | translate}}</li>
            <li>{{'contenidoMain.politicas.lista11.item15' | translate}}</li>
            <li>{{'contenidoMain.politicas.lista11.item16' | translate}}</li>
            <li>{{'contenidoMain.politicas.lista11.item17' | translate}}</li>
          </ul>
          <p style="text-align: justify;">{{'contenidoMain.politicas.texto35' | translate}}</p>
          <p style="text-align: justify;">{{'contenidoMain.politicas.texto36' | translate}}</p>
          <p style="text-align: justify;">{{'contenidoMain.politicas.texto37' | translate}}</p>
          <p style="text-align: justify;">{{'contenidoMain.politicas.texto38' | translate}}</p>
          <p style="text-align: justify;">{{'contenidoMain.politicas.texto39' | translate}}</p>
          <p style="text-align: justify;">{{'contenidoMain.politicas.texto40' | translate}}</p>
          <p style="text-align: justify;">{{'contenidoMain.politicas.texto41' | translate}} </p>
          <p style="text-align: justify;">{{'contenidoMain.politicas.texto42' | translate}}</p>
          <p style="text-align: justify;">{{'contenidoMain.politicas.texto43' | translate}}</p>
          <p style="text-align: justify;">{{'contenidoMain.politicas.texto44' | translate}}</p>
          <p style="text-align: justify;">{{'contenidoMain.politicas.texto45' | translate}} </p>
          <p style="text-align: justify;">{{'contenidoMain.politicas.texto46' | translate}} </p>
          <p style="text-align: justify;"> {{'contenidoMain.politicas.texto47' | translate}}</p>
          <p style="text-align: justify;">{{'contenidoMain.politicas.texto48' | translate}}</p>
          <ul style="text-align: justify;">
            <li>{{'contenidoMain.politicas.lista12.item1' | translate}}</li>
            <li>{{'contenidoMain.politicas.lista12.item2' | translate}}</li>
            <li>{{'contenidoMain.politicas.lista12.item3' | translate}}</li>
            <li>{{'contenidoMain.politicas.lista12.item4' | translate}}</li>
            <li>{{'contenidoMain.politicas.lista12.item5' | translate}}</li>

          </ul>
          <p style="text-align: justify;">{{'contenidoMain.politicas.texto49' | translate}}</p>
          <p style="text-align: justify;">{{'contenidoMain.politicas.texto50' | translate}}</p>
        </div>
      </div>
    </div>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TerminosUsoComponent } from '../terminos-uso/terminos-uso.component';
import { PoliticasPrivacidadComponent } from '../politicas-privacidad/politicas-privacidad.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  language: string = 'es'
  currentYear = new Date().getFullYear();

  modalRef: BsModalRef;

  constructor(private translate: TranslateService, private modalService: BsModalService) { }

  ngOnInit(): void {
    this.language = this.translate.currentLang;
    this.translate.onLangChange.subscribe((event) => {
      this.language = event.lang;
    })
  }

  openModalTerminos() {
    this.modalRef = this.modalService.show(TerminosUsoComponent, {
      class: 'modal-dialog-centered modal-xl',
      backdrop: 'static',
      keyboard: false
    });
  }

  openModalPoliticas() {
    this.modalRef = this.modalService.show(PoliticasPrivacidadComponent, {
      class: 'modal-dialog-centered modal-xl',
      backdrop: 'static',
      keyboard: false
    });
  }
}

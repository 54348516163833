import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CrearFuentePago } from 'src/app/models/facturacion/crear-fuente-pago.model'

@Injectable({
  providedIn: 'root'
})
export class CrearFuentePagoService {

  private urlCrearFuentePago: string = environment.urlCrearFuentePago;

  constructor(private http: HttpClient) { }

  crearFuentePago(metododepago: CrearFuentePago): Observable<CrearFuentePago> {
    return this.http.post<CrearFuentePago>(this.urlCrearFuentePago, metododepago);
  }
}
import { Component } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ModalSeleccionarPlanComponent } from '../modal-seleccionar-plan/modal-seleccionar-plan.component';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-modal-servicio-gratis-finalizado',
  templateUrl: './modal-servicio-gratis-finalizado.component.html',
  styleUrls: ['./modal-servicio-gratis-finalizado.component.scss']
})
export class ModalServicioGratisFinalizadoComponent {

  constructor(private modalRef: BsModalRef, private modalService: BsModalService, private authService: MsalService) {

  }

  onClose() {
    this.modalRef.hide();
  }

  openModalPlans() {
    this.modalRef.hide();

    setTimeout(() => {
      this.modalService.show(ModalSeleccionarPlanComponent, {
        class: 'modal-dialog-centered modal-xl',
        backdrop: 'static',
        keyboard: false
      });
    }, 500);
  }

  logout() {
    this.authService.logoutRedirect();
    localStorage.removeItem('token');
  }
}

<div class="notificaciones-panel" *ngIf="mostrarNotificaciones">
  <div *ngIf="enviarNotificaciones.data.length > 0">
    <ul *ngFor="let notificacion of enviarNotificaciones.data">
      <li>
        <img src="../../../../assets/images/notificaciones/{{notificacion.categoria}}.svg" alt="{{notificacion.categoria}}" width="50px">
        <p>{{notificacion.textoNotificacion}}</p>
      </li>
    </ul>
  </div>

  <div *ngIf="enviarNotificaciones.data.length === 0">
    <ul>
      <li>
        <svg xmlns="http://www.w3.org/2000/svg" height="50" width="50" viewBox="0 0 512 512" fill="#150E59"><path opacity="1" d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-384c13.3 0 24 10.7 24 24V264c0 13.3-10.7 24-24 24s-24-10.7-24-24V152c0-13.3 10.7-24 24-24zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"/></svg>
        <p>{{'notificaciones.sinNotificaciones' | translate}}</p>
      </li>
    </ul>
  </div>
</div>

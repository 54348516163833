<div class="modal-body">
  <div class="modal-plan-container">
    <div class="row row-container">
      <div class="col">
        <div class="d-flex flex-wrap flex-column justify-content-center align-items-center">
          <h4 class="fw-bold">Selecciona el canal que deseas obtener</h4>
          
          <div class="d-flex flex-wrap gap-3 justify-content-center pt-2">
            <div class="form-check" id="inputs-radio">
              <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" (click)="showSection('web')" [checked]="showSectionWeb">
              <label class="form-check-label fw-bold" for="flexRadioDefault1">{{'contenidoMain.sectionDisponible.web' | translate}}</label>
            </div>
            <div class="form-check" id="inputs-radio">
              <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" (click)="showSection('kiosco')" [checked]="showSectionKiosco">
              <label class="form-check-label fw-bold" for="flexRadioDefault2">{{'contenidoMain.sectionDisponible.kiosco' | translate}}</label>
            </div>
            <div class="form-check" id="inputs-radio">
              <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3" (click)="showSection('whatsapp')" [checked]="showSectionWhatsapp">
              <label class="form-check-label fw-bold" for="flexRadioDefault3">{{'contenidoMain.sectionDisponible.whatsapp' | translate}}</label>
            </div>
          </div>

          <div>
            <div id="tabview-frequency-suscription" class="text-center">
              <p-tabView>
                <p-tabPanel header="Mensual">
                  <div class="d-flex flex-wrap gap-4 justify-content-start align-items-center flex-row pt-4">
                    <div class="plan" [ngClass]="{'pBasic': plan.titulo === 'Básico', 'pAdvanced': plan.titulo === 'Estándar', 'pEnterprise': plan.titulo === 'Premium'}" *ngFor="let plan of (showSectionWeb ? planesCanalWebMensual : (showSectionKiosco ? planesCanalKioscoMensual : planesCanalWhatsappMensual))">
                      <div class="titlePlans" [ngClass]="{'titlePlanBasic': plan.titulo === 'Básico', 'titlePlanAdvanced': plan.titulo === 'Estándar', 'titlePlanEnterprise': plan.titulo === 'Premium'}">
                        <span>{{plan.titulo}}</span>
                      </div>
                      <div class="container">
                        <p class="text-center pricePlan"><b>USD ${{plan.precioPlan | number}}</b>/mes</p>
                        <hr class="bg-dark w-100 m-auto" style="height: 2px" />
        
                        <div class="info-plan px-3">
                          <p class="mt-2"><b>{{plan.cantidadIncluida}}</b> {{plan.canal === 'kiosco' ? 'Kioscos' : 'contenidoDashboard.suscripcion.cambiarPlan.mensajes' | translate}}</p>
                          <p *ngIf="plan.canal === 'kiosco'">Mensajes ilimitados</p>
                          <p><b>{{plan.capacidadAlmacenamiento}} (Mb)</b> de almacenamiento</p>
                          <p *ngIf="plan.canal !== 'kiosco'">Precio del mensaje adicional: <b>${{plan.precioUnidadAdicional}}</b></p>
                        </div>
                        
                        <div class="text-center">
                          <button type="button" class="shadow-sm" [ngClass]="{'button-basic-outline': plan.titulo === 'Básico', 'button-advanced-outline': plan.titulo === 'Estándar', 'button-enterprise-outline': plan.titulo === 'Premium'}" (click)="continueWithPlan(plan.idPlan)">
                            {{'contenidoDashboard.suscripcion.cambiarPlan.elegir' | translate}}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </p-tabPanel>
        
                <p-tabPanel header="Anual">
                  <div class="d-flex flex-wrap gap-4 justify-content-start align-items-center flex-row pt-4">
                    <div class="plan" [ngClass]="{'pBasic': plan.titulo === 'Básico', 'pAdvanced': plan.titulo === 'Estándar', 'pEnterprise': plan.titulo === 'Premium'}" *ngFor="let plan of (showSectionWeb ? planesCanalWebAnual : (showSectionKiosco ? planesCanalKioscoAnual : planesCanalWhatsappAnual))">
                      <div class="titlePlans" [ngClass]="{'titlePlanBasic': plan.titulo === 'Básico', 'titlePlanAdvanced': plan.titulo === 'Estándar', 'titlePlanEnterprise': plan.titulo === 'Premium'}">
                        <span>{{plan.titulo}}</span>
                      </div>
                      <div class="container">
                        <p class="text-center pricePlan"><b>USD ${{plan.precioPlan | number}}</b>/mes</p>
                        <hr class="bg-dark w-100 m-auto" style="height: 2px" />
        
                        <div class="info-plan px-3">
                          <p class="mt-2"><b>{{plan.cantidadIncluida}}</b> {{plan.canal === 'kiosco' ? 'Kioscos' : 'contenidoDashboard.suscripcion.cambiarPlan.mensajes' | translate}}</p>
                          <p *ngIf="plan.canal === 'kiosco'">Mensajes ilimitados</p>
                          <p><b>{{plan.capacidadAlmacenamiento}} (Mb)</b> de almacenamiento</p>
                          <p>Descuento del 10% sobre el valor mensual.</p>
                        </div>
                        
                        <div class="text-center">
                          <button type="button" class="shadow-sm" [ngClass]="{'button-basic-outline': plan.titulo === 'Básico', 'button-advanced-outline': plan.titulo === 'Estándar', 'button-enterprise-outline': plan.titulo === 'Premium'}" (click)="continueWithPlan(plan.idPlan)">
                            {{'contenidoDashboard.suscripcion.cambiarPlan.elegir' | translate}}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </p-tabPanel>
              </p-tabView>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
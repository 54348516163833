import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { ClienteBasico, Clientes } from 'src/app/models/facturacion/cliente.model';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ClienteService {
  private urlCliente = environment.urlCliente;

  constructor(private http: HttpClient, private translate: TranslateService) {}

  obtenerCliente(tenant: string): Observable<Clientes[]> {
    return this.http.get<Clientes[]>(`${this.urlCliente}/${tenant}`);
  }

  crearClienteCompleto(cliente: Clientes): Observable<Clientes> {
    return this.http.post<Clientes>(`${this.urlCliente}/crear-completo`, cliente);
  }
  crearClienteBasico(cliente: ClienteBasico): Observable<ClienteBasico> {
    return this.http.post<ClienteBasico>(`${this.urlCliente}/crear-basico`, cliente);
  }

  actualizarCliente(cliente: Clientes): Observable<Clientes> {
    return this.http.patch<Clientes>(`${this.urlCliente}/${cliente.tenant}`, cliente);
  }

  getTipoPersonaOptions(): Observable<any[]> {
    return of([
      { value: 'Person', label: this.translate.instant('contenidoDashboard.facturacion.informacionTributaria.formulario.personaOptions.person') },
      { value: 'Company', label: this.translate.instant('contenidoDashboard.facturacion.informacionTributaria.formulario.personaOptions.company') }
    ]);
  }

  getCodigoResponsabilidadOptions() {
    return of([
      { value: 'R-99-PN', label: this.translate.instant('contenidoDashboard.facturacion.informacionTributaria.formulario.codigoResponsabilidadOptions.R-99-PN') },
      { value: 'O-13', label: this.translate.instant('contenidoDashboard.facturacion.informacionTributaria.formulario.codigoResponsabilidadOptions.O-13') },
      { value: 'O-15', label: this.translate.instant('contenidoDashboard.facturacion.informacionTributaria.formulario.codigoResponsabilidadOptions.O-15') },
      { value: 'O-23', label: this.translate.instant('contenidoDashboard.facturacion.informacionTributaria.formulario.codigoResponsabilidadOptions.O-23') },
      { value: 'O-47', label: this.translate.instant('contenidoDashboard.facturacion.informacionTributaria.formulario.codigoResponsabilidadOptions.O-47') },
    ]);
  }

  getDocumentoOptions() {
    return of([
      { value: '13', label: this.translate.instant('contenidoDashboard.facturacion.informacionTributaria.formulario.documentoOptions.13') },
      { value: '31', label: this.translate.instant('contenidoDashboard.facturacion.informacionTributaria.formulario.documentoOptions.31') },
      { value: '22', label: this.translate.instant('contenidoDashboard.facturacion.informacionTributaria.formulario.documentoOptions.22') },
      { value: '42', label: this.translate.instant('contenidoDashboard.facturacion.informacionTributaria.formulario.documentoOptions.42') },
      { value: '50', label: this.translate.instant('contenidoDashboard.facturacion.informacionTributaria.formulario.documentoOptions.50') },
      { value: '47', label: this.translate.instant('contenidoDashboard.facturacion.informacionTributaria.formulario.documentoOptions.47') },
    ]);
  }
}

import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-politicas-privacidad',
  templateUrl: './politicas-privacidad.component.html',
  styleUrls: ['./politicas-privacidad.component.scss']
})
export class PoliticasPrivacidadComponent {
  constructor(private modalRef: BsModalRef) {

  }

  closeModal() {
    this.modalRef.hide();
  }

}

import { Component, AfterViewInit, OnDestroy } from "@angular/core";
import { LocalStorageService } from "./services/common/local-storage.service";
import { TranslateService } from "@ngx-translate/core";
import { driver, Driver } from "driver.js";
import { NavigationEnd, Router } from '@angular/router';
import { Subscription, filter, firstValueFrom } from "rxjs";

enum TutorialKeys {
  dashboardTutorialSeen = 'dashboardTutorialSeen',
  useCaseCreationTutorialSeen = 'useCasesTutorialSeen',
  flowCreationTutorialSeen = 'flowCreationTutorialSeen',
}

@Component({
  selector: "app-tutorial",
  template: '',
  styles: [""]
})

export class TutorialComponent implements AfterViewInit, OnDestroy {
  subscriptions$: Subscription[] = [];

  popoverConfig = {
    overlayColor: '#000000',
    showProgress: true,
    prevBtnText: '',
    nextBtnText: '',
    doneBtnText: '',
    // popoverClass: 'driverjs-theme',
  }

  private dashboardTutorialObj: Driver;
  private useCaseCreationTutorialObj: Driver;

  constructor(
    private router: Router,
    private localStorageService: LocalStorageService,
    private translate: TranslateService
  ) { }

  // AfterViewInit is used to wait for the DOM to be rendered to show the tutorial to the user
  ngAfterViewInit() {
    this.subscriptions$.push(this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe({
      next: (event: NavigationEnd) => {
        let dashboardTutorialSeen = this.localStorageService.getItem(TutorialKeys.dashboardTutorialSeen);
        if (!dashboardTutorialSeen && event.url.includes('dashboard')) {
          this.startDashboardTutorial();
        }

        let useCaseCreationTutorialSeen = this.localStorageService.getItem(TutorialKeys.useCaseCreationTutorialSeen);
        if (dashboardTutorialSeen && !useCaseCreationTutorialSeen && event.url === '/dashboard/web/create') {
          this.startUseCaseCreationTutorial();
        }
      }
    }));

    this.subscriptions$.push(this.translate.get('contenidoDashboard.tutoriales.popOverConfig').subscribe((traducciones) => {
      this.popoverConfig.nextBtnText = traducciones.botonSiguiente;
      this.popoverConfig.prevBtnText = traducciones.botonAnterior;
      this.popoverConfig.doneBtnText = traducciones.botonFinalizar;
    }));
  }

  // Tutorial dashboard
  private async startDashboardTutorial() {
    let dashboardTutorialTraductions = await firstValueFrom(this.translate.get('contenidoDashboard.tutoriales.tutorialDashboard'));

    this.dashboardTutorialObj = driver({
      ...this.popoverConfig,
      steps: [
        {
          element: '#panel',
          popover: {
            title: dashboardTutorialTraductions.paso1.titulo,
            description: dashboardTutorialTraductions.paso1.descripcion,
          }
        },
        {
          element: '.separacion',
          popover: {
            title: dashboardTutorialTraductions.paso2.titulo,
            description: dashboardTutorialTraductions.paso2.descripcion,
          }
        },
        {
          element: '#sidebar-button',
          popover: {
            title: dashboardTutorialTraductions.paso3.titulo,
            description: dashboardTutorialTraductions.paso3.descripcion,
          }
        },
        {
          element: '#navbarNavDropdown',
          popover: {
            title: dashboardTutorialTraductions.paso4.titulo,
            description: dashboardTutorialTraductions.paso4.descripcion,
          },
        },
        {
          element: '#notifications-navbar',
          popover: {
            title: dashboardTutorialTraductions.paso5.titulo,
            description: dashboardTutorialTraductions.paso5.descripcion,
          }
        },
        {
          element: '#language-navbar',
          popover: {
            title: dashboardTutorialTraductions.paso6.titulo,
            description: dashboardTutorialTraductions.paso6.descripcion,
          }
        },
        {
          element: '#profile-navbar',
          popover: {
            title: dashboardTutorialTraductions.paso7.titulo,
            description: dashboardTutorialTraductions.paso7.descripcion,
            onNextClick: () => {
              document.body.classList.remove('disable-interaction');
              this.localStorageService.setItem(TutorialKeys.dashboardTutorialSeen, true);
              this.dashboardTutorialObj.moveNext();
            }
          }
        },
      ],
      onDestroyStarted: () => {
        document.body.classList.remove('disable-interaction');
        this.localStorageService.setItem(TutorialKeys.dashboardTutorialSeen, true);
        this.dashboardTutorialObj.destroy();
      }
    });

    this.dashboardTutorialObj.drive();
    document.body.classList.add('disable-interaction');
    document.body.classList.remove('driver-active', 'driver-fade');
  }

  // Tutorial use case creation
  private async startUseCaseCreationTutorial() {
    let useCaseCreationTutorialTraductions = await firstValueFrom(this.translate.get('contenidoDashboard.tutoriales.tutorialCrearCasoDeUso'));

    this.useCaseCreationTutorialObj = driver({
      ...this.popoverConfig,
      steps: [
        {
          element: '#panel',
          popover: {
            title: useCaseCreationTutorialTraductions.paso1.titulo,
            description: useCaseCreationTutorialTraductions.paso1.descripcion,
          }
        },
        {
          element: '#useCaseSettings',
          popover: {
            title: useCaseCreationTutorialTraductions.paso2.titulo,
            description: useCaseCreationTutorialTraductions.paso2.descripcion,
          }
        },
        {
          element: '#avatarSettings',
          popover: {
            title: useCaseCreationTutorialTraductions.paso3.titulo,
            description: useCaseCreationTutorialTraductions.paso3.descripcion,
          }
        },
        {
          element: '#initialViewSettings',
          popover: {
            title: useCaseCreationTutorialTraductions.paso4.titulo,
            description: useCaseCreationTutorialTraductions.paso4.descripcion,
          },
        },
        {
          element: '#interfazSettings',
          popover: {
            title: useCaseCreationTutorialTraductions.paso5.titulo,
            description: useCaseCreationTutorialTraductions.paso5.descripcion,
          },
        },
        {
          element: '#buttonSaveUseCase',
          popover: {
            title: useCaseCreationTutorialTraductions.paso6.titulo,
            description: useCaseCreationTutorialTraductions.paso6.descripcion,
            onNextClick: () => {
              document.body.classList.remove('disable-interaction');
              this.localStorageService.setItem(TutorialKeys.useCaseCreationTutorialSeen, true);
              this.useCaseCreationTutorialObj.moveNext();
            }
          },
        }
      ],
      onDestroyStarted: () => {
        document.body.classList.remove('disable-interaction');
        this.localStorageService.setItem(TutorialKeys.useCaseCreationTutorialSeen, true);
        this.useCaseCreationTutorialObj.destroy();
      }
    });

    this.useCaseCreationTutorialObj.drive();
    document.body.classList.add('disable-interaction');
    document.body.classList.remove('driver-active', 'driver-fade');
  }

  ngOnDestroy() {
    document.body.classList.remove('disable-interaction');
    this.subscriptions$.forEach(subscription => subscription.unsubscribe());
  }
}

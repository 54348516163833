import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { filter } from 'rxjs';
import { PermisosService } from '../services/dashboard/usuarios/permisos.service';
import { PermisosDto } from '../models/dashboard/usuarios/permisos.model';

@Injectable()
export class PermisosGuard implements CanActivate {

  private dataB2C: any;

  constructor(
    private _router: Router,
    private _msalBroadcastService: MsalBroadcastService,
    private _authService: MsalService,
    private permisosService: PermisosService,
  ) { }

  canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise<boolean>((resolve, _reject) => {
      const routeUrl: string = state.url;

      if (routeUrl.includes('/dashboard/instructive') || routeUrl.includes('/dashboard/initial-configuration')) {
        resolve(true);
        return;
      }
      this._msalBroadcastService.inProgress$.pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None)
      )
        .subscribe(() => {
          const activeAccount = this._authService.instance.getActiveAccount();
          if (activeAccount) {
            this.getClaims(activeAccount.idTokenClaims);
          }

          const oid = this.dataB2C.oid;

          this.permisosService.obtenerPermisos(oid).subscribe({
            next: (data: any) => {
              const hasPermiso = this.checkPermissionsForRoute(routeUrl, data.data);

              if (hasPermiso) {
                resolve(true);
              } else {
                //   this._router.navigate(['/dashboard/instructive']);
                const firstAvailableUrl = this.getFirstAvailableUrl(data.data);
                if (firstAvailableUrl) {
                  this._router.navigate([`/dashboard/${firstAvailableUrl}`]);
                } else {
                  this._router.navigate(['/dashboard/instructive']); // Si no hay ninguna URL disponible, redirigir a instructive
                }
                resolve(false);
              }
            },
            error: (error) => {
              console.error('Error al obtener permisos:', error);
              resolve(false);
            }
          });
        });
    });
  }

  checkPermissionsForRoute(routeUrl: string, permisos: PermisosDto): boolean {
    const rutaVistaMap: { [key: string]: string } = {
      'web': 'Web',
      'kiosco': 'Kiosko',
      'narradora': 'Narradora',
      'whatsapp': 'Whatsapp',
      'llamadas': 'Llamadas',
      'users': 'Usuarios',
      'billing': 'Facturacion',
      'suscripcion': 'Suscripcion',
      'metrics': 'Estadisticas',
      'subscriptionKey': 'SubscriptionKey'
    };

    // const urls = ['web', 'kiosco', 'narradora', 'whatsapp', 'llamadas', 'users', 'billing', 'suscripcion', 'metrics', 'subscriptionKey'];
    for (const key in rutaVistaMap) {
      const vista = rutaVistaMap[key];
      if (routeUrl.includes(key) && permisos.Vistas[vista]) {
        return true;
      }
    }

    return false;
  }

  getFirstAvailableUrl(permisos: PermisosDto): string | null {
    const rutaVistaMap: { [key: string]: string } = {
      'Web': 'web',
      'Kiosco': 'kiosko',
      'Narradora': 'narradora',
      'Whatsapp': 'whatsapp',
    };

    for (const url in rutaVistaMap) {
      if (permisos.Vistas[rutaVistaMap[url]]) {
        return url;
      }
    }
    return null; // Si no hay ninguna URL disponible, retorna null
  }

  getClaims(claims: any) {
    this.dataB2C = claims;
  }
}

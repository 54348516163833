import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FacturacionService {

  constructor(private http: HttpClient) { }

  private publicKey = environment.publicKey;
  private wompiBase = environment.wompiBase;
  private apiUrlTarjetaToken = `${this.wompiBase}tokens/cards`;
  private apiUrlAceptacionToken = `${this.wompiBase}merchants/${this.publicKey}`;

  getToken(body: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.publicKey
    });

    return this.http.post(this.apiUrlTarjetaToken, body, { headers });
  }

  async getAcceptanceToken(): Promise<any> {
    try {
      const response = await fetch(this.apiUrlAceptacionToken);
      if (response.ok) {
        const data = await response.json();
        return data.data.presigned_acceptance.acceptance_token;
      } else {
        throw new Error('Error al obtener el Token de Aceptación');
      }
    } catch (error) {
      throw error;
    }
  }
}

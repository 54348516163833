import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { BehaviorSubject, Observable, catchError, filter, finalize, from, switchMap, take, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MsalService } from '@azure/msal-angular';
import { AuthenticationResult, InteractionRequiredAuthError } from '@azure/msal-browser';

@Injectable()
export class SubscriptionKeyInterceptor implements HttpInterceptor {
  private isRefreshingToken = false;
  private tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  UrlBaseB2C = 'https://natisaas.onmicrosoft.com/c2d47157-26fb-41da-9d54-39355f077001/';
  ScopeApiRead = 'Api.Read';

  private subscriptionKey = environment.subscriptionKey;
  private excludedUrls: string[] = [
    'https://sandbox.wompi.co/v1/' // Añade aquí el endpoint que quieres excluir
  ]; 

  constructor(private msalService: MsalService) { }
  // intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
  //   const isExcluded = this.excludedUrls.some(url => req.url.includes(url));

  //   const clonedRequest = req.clone({
  //     setHeaders: {
  //       'Ocp-Apim-Subscription-Key': this.subscriptionKey,
  //       'Authorization': `Bearer ${localStorage.getItem('token') || ''}`
  //     }
  //   });
  //   return next.handle(clonedRequest).pipe(
  //     catchError(error => {
  //       if (error instanceof HttpErrorResponse && error.status === 401) {
  //         return this.handleTokenExpiration(clonedRequest, next);
  //       }
  //       return throwError(() => error);
  //     })
  //   );
  // }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isExcluded = this.excludedUrls.some(url => req.url.includes(url));

    let clonedRequest: HttpRequest<any>;
    if (isExcluded) {
      // Clona la solicitud sin el encabezado de autorización
      clonedRequest = req.clone({
        setHeaders: {
        }
      });
    } else {
      // Clona la solicitud con el encabezado de autorización
      clonedRequest = req.clone({
        setHeaders: {
          'Ocp-Apim-Subscription-Key': this.subscriptionKey,
          'Authorization': `Bearer ${localStorage.getItem('token') || ''}`
        }
      });
    }

    return next.handle(clonedRequest).pipe(
      catchError(error => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          return this.handleTokenExpiration(clonedRequest, next);
        }
        return throwError(() => error);
      })
    );
  }

  private addAuthHeaders(request: HttpRequest<any>): HttpRequest<any> {
    const token = localStorage.getItem('token');
    const headersConfig: any = {
      'Ocp-Apim-Subscription-Key': this.subscriptionKey,
    };

    if (token) {
      headersConfig['Authorization'] = `Bearer ${token}`;
    }

    return request.clone({ setHeaders: headersConfig });
  }
  private handleTokenExpiration(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true;
      this.tokenSubject.next(null);

      return from(this.msalService.instance.acquireTokenSilent({
        account: this.msalService.instance.getActiveAccount(),
        scopes: [this.UrlBaseB2C + this.ScopeApiRead],
      })).pipe(
        switchMap((response: AuthenticationResult) => {
          const newToken = response.accessToken;
          if (newToken) {
            localStorage.setItem('token', newToken);
            this.tokenSubject.next(newToken);
            return next.handle(this.addAuthHeaders(request));
          } else {

            this.msalService.logout();
            return throwError(() => new Error('Token refresh failed'));
          }
        }),
        catchError(error => {
          if (error instanceof InteractionRequiredAuthError) {
            this.msalService.logout();
          }
          return throwError(() => error);
        }),
        finalize(() => {
          this.isRefreshingToken = false;
        })
      );
    }

    return this.tokenSubject.pipe(
      filter(token => token !== null),
      take(1),
      switchMap(() => next.handle(this.addAuthHeaders(request)))
    );
  }
}

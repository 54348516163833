import { Component, Input } from '@angular/core';
import { Notificaciones } from 'src/app/models/notificaciones/notificaciones.model';

@Component({
  selector: 'app-notificaciones',
  templateUrl: './notificaciones.component.html',
  styleUrls: ['./notificaciones.component.scss']
})
export class NotificacionesComponent {
  @Input() mostrarNotificaciones = false;
  @Input() tenant = '';
  @Input() enviarNotificaciones: Notificaciones;
}

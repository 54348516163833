import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

interface Lang {
  name: string,
  code: string
}
@Component({
  selector: 'app-switchlang',
  templateUrl: './switchlang.component.html',
  styleUrls: ['./switchlang.component.scss']
})
export class SwitchlangComponent implements OnInit {

  languages: Lang[];
  selectedLanguage: Lang;

  constructor(public translate: TranslateService) {
    this.languages = [
      {name: 'ENGLISH', code: 'en'},
      {name: 'ESPAÑOL', code: 'es'},
      {name: 'FRANÇAIS', code: 'fr'},
      {name: 'PORTUGUÊS', code: 'pt'},
      {name: 'ITALIAN', code: 'it'},
      {name: 'DUTCH', code: 'nl'},
      {name: 'GERMAN', code: 'de'},
      {name: '中文简体', code: 'zh-hans'},
    ];
  }

  ngOnInit(): void {
    const lang = localStorage.getItem('language') || this.translate.getBrowserLang();
    // const lang = localStorage.getItem('language') || 'en';
    if ( lang !== 'en' && lang !== 'es' && lang !== 'fr' && lang !== 'pt' && lang !== 'it' && lang !== 'nl' && lang !== 'de' && lang !== 'zh-hans' ) {
      this.selectedLanguage = this.languages[0];
      this.switchLang();
    } else {
      this.selectedLanguage = this.languages.find( l => l.code === lang);
      this.switchLang();
    }
  }

  switchLang() {
    try {
      this.translate.use(this.selectedLanguage.code);

      // Save the selected language to local storage
      localStorage.setItem('language', this.selectedLanguage.code);
    } catch (error) {
      console.log(error);
    }
  }

}

<div class="modal-body">
    <div class="register-container">
      <div class="row m-0 row-container">
        <div class="col-md-6 d-block m-auto">
          <div class="w-100 bg-nati-avatar">
            <img src="../../../assets/images/nati-new-logo.png" class="position-absolute top-0 m-3" alt="Nati"
              width="155" height="65">
  
            <div class="text-center">
              <img src="../../../assets/images/landing/nati.png" alt="Nati avatar" class="img-fluid avatar"
                style="    margin-top: 12%;">
            </div>
  
            <div class="blur-container">
              <div class="blur-text">
                <h5>{{'contenidoMain.modales.textImg1' | translate}} <br><b>{{'contenidoMain.modales.textImg2' | translate}}</b></h5>
                <p class="mt-3">{{'contenidoMain.modales.textImgSubtitulo' | translate}}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <h4 class="text-center fw-bold">{{'contenidoDashboard.facturacion.informacionTributaria.titulo' | translate}}</h4>
            <hr class="line-unavailable">
          </div>
          <div class="container-form row">
            <div *ngIf="loading" class="loading-indicator">
              {{'contenidoDashboard.loading' | translate}}
            </div>
            
            <form [formGroup]="informacionTributaria" id="payment-form-modal" (ngSubmit)="onSubmit()">
              <div class="container-form">
                <div class="col-md-8">
                  <div class="payment-form">
                    <label for="tipo-persona"><b>{{'contenidoDashboard.facturacion.informacionTributaria.formulario.campo1' | translate}}</b></label>
                    <p-dropdown id="tipo-persona" class="dropdown-modal-info-tributaria" [options]="tipoPersona"
                      formControlName="tipoPersona" optionLabel="label"
                      placeholder="{{'contenidoDashboard.facturacion.informacionTributaria.formulario.campo1placeholder' | translate}}"></p-dropdown>
                      <div class="text-danger" style="font-size: 14px;"*ngIf="informacionTributaria.get('tipoPersona').hasError('required') && (informacionTributaria.get('tipoPersona').touched)">
                        {{'contenidoDashboard.facturacion.informacionTributaria.formulario.alert' | translate}}
                      </div>
                  </div>
                  <div class="payment-form">
                    <label for="tipo-documento"><b>{{'contenidoDashboard.facturacion.informacionTributaria.formulario.campo3' | translate}}</b></label>
                    <p-dropdown id="tipo-documento" class="dropdown-modal-info-tributaria" [options]="documentos"
                      formControlName="tipoDocumento" optionLabel="label"
                      placeholder="{{'contenidoDashboard.facturacion.informacionTributaria.formulario.campo3placeholder' | translate}}"></p-dropdown>
                      <div class="text-danger" style="font-size: 14px;"  *ngIf="informacionTributaria.get('tipoDocumento').hasError('required') && (informacionTributaria.get('tipoDocumento').touched)">
                        {{'contenidoDashboard.facturacion.informacionTributaria.formulario.alert' | translate}}
                      </div>
                  </div>
                  <div class="payment-form">
                    <label for="direccion"><b>{{'contenidoDashboard.facturacion.informacionTributaria.formulario.campo5' | translate}}</b></label>
                    <input type="text" id="direccion" class="form-control" placeholder="{{'contenidoDashboard.facturacion.informacionTributaria.formulario.campo5placeholder' | translate}}"
                      formControlName="direccion" required>
                      <!-- <div class="error-message" *ngIf="informacionTributaria.get('direccion').hasError('required') && (informacionTributaria.get('direccion').touched)">
                        {{'contenidoDashboard.facturacion.informacionTributaria.formulario.alert' | translate}}
                      </div> -->
                      <!-- <div class="error-message" *ngIf="informacionTributaria.get('direccion').hasError('required') && informacionTributaria.get('direccion').hasError('maxlength') && informacionTributaria.get('direccion').touched"> -->
                        <div class="text-danger" style="font-size: 14px;"
                            *ngIf="informacionTributaria.get('direccion').invalid && (informacionTributaria.get('direccion').dirty || informacionTributaria.get('direccion').touched)">
                            <ng-container *ngIf="informacionTributaria.get('direccion').errors['pattern']">
                              {{'contenidoDashboard.facturacion.metododepago.alertas.campoObligatorio' | translate}}
                            </ng-container>
                            <ng-container *ngIf="informacionTributaria.get('direccion').errors['minlength']">
                              {{'contenidoDashboard.facturacion.informacionTributaria.formulario.alertas.noCumpleCaracteres' | translate}}
                            </ng-container>
                            <ng-container *ngIf="informacionTributaria.get('direccion').errors['maxlength']">
                              {{'contenidoDashboard.facturacion.metododepago.alertas.maximoCaracteres1' | translate}} 20 {{'contenidoDashboard.facturacion.metododepago.alertas.caracteres' | translate}}
                            </ng-container>
                            <ng-container *ngIf="informacionTributaria.get('direccion').hasError('required')">
                              {{'contenidoDashboard.facturacion.informacionTributaria.formulario.alert' | translate}}
                            </ng-container>
                          </div>
                      <!-- </div> -->
                  </div>
                  <div class="payment-form">
                    <label for="nombre-contacto"><b>{{'contenidoDashboard.facturacion.informacionTributaria.formulario.campo7' | translate}}</b></label>
                    <input type="text" id="nombre-contacto" class="form-control" placeholder="{{'contenidoDashboard.facturacion.informacionTributaria.formulario.campo7placeholder' | translate}}"
                      formControlName="nombreContacto" required>
                      <div class="text-danger" style="font-size: 14px;"
                            *ngIf="informacionTributaria.get('nombreContacto').invalid && (informacionTributaria.get('nombreContacto').dirty || informacionTributaria.get('nombreContacto').touched)">
                            <ng-container *ngIf="informacionTributaria.get('nombreContacto').errors['pattern']">
                              {{'contenidoDashboard.facturacion.informacionTributaria.formulario.alertas.nombre' | translate}}
                            </ng-container>
                            <ng-container *ngIf="informacionTributaria.get('nombreContacto').errors['minlength']">
                              {{'contenidoDashboard.facturacion.informacionTributaria.formulario.alertas.noCumpleCaracteres' | translate}}
                            </ng-container>
                            <ng-container *ngIf="informacionTributaria.get('nombreContacto').errors['maxlength']">
                              {{'contenidoDashboard.facturacion.metododepago.alertas.maximoCaracteres1' | translate}} 20 {{'contenidoDashboard.facturacion.metododepago.alertas.caracteres' | translate}}
                            </ng-container>
                            <ng-container *ngIf="informacionTributaria.get('nombreContacto').hasError('required')">
                              {{'contenidoDashboard.facturacion.informacionTributaria.formulario.alert' | translate}}
                            </ng-container>
                          </div>
                      <!-- <div class="error-message" *ngIf="informacionTributaria.get('nombreContacto').hasError('required') && (informacionTributaria.get('nombreContacto').touched)">
                        {{'contenidoDashboard.facturacion.informacionTributaria.formulario.alert' | translate}}
                      </div>
                      <div class="error-message" *ngIf="informacionTributaria.get('nombreContacto').hasError('maxlength') && informacionTributaria.get('direccion').touched">
                        {{'contenidoDashboard.facturacion.informacionTributaria.formulario.alert3' | translate}}
                      </div> -->
                  </div>
                  <div class="payment-form">
                    <label for="correo-factura"><b>{{'contenidoDashboard.facturacion.informacionTributaria.formulario.campo9' | translate}}</b></label>
                    <input type="text" id="correo-factura" class="form-control" placeholder="{{'contenidoDashboard.facturacion.informacionTributaria.formulario.campo9placeholder' | translate}}"
                      formControlName="correoFactura" required>
                      <div class="text-danger" *ngIf="informacionTributaria.get('correoFactura').invalid && (informacionTributaria.get('correoFactura').touched)">
                        <div *ngIf="informacionTributaria.get('correoFactura').hasError('required')">{{'contenidoDashboard.facturacion.informacionTributaria.formulario.alert' | translate}}</div>
                        <!-- <div *ngIf="informacionTributaria.get('correoFactura').hasError('email')">{{'contenidoDashboard.facturacion.informacionTributaria.formulario.alert4' | translate}}</div> -->
                        <div *ngIf="informacionTributaria.get('correoFactura').hasError('pattern')">
                          {{'contenidoDashboard.facturacion.informacionTributaria.formulario.alertas.email' | translate}}
                        </div>
                      </div>
                  </div>
                </div>
                <div class="col-md-8">
                  <div class="payment-form">
                    <label for="codigo-fiscal"><b>{{'contenidoDashboard.facturacion.informacionTributaria.formulario.campo2' | translate}}</b></label>
                    <p-dropdown id="codigo-fiscal" class="dropdown-modal-info-tributaria" [options]="codigo"
                      formControlName="codigoFiscal" optionLabel="label"
                      placeholder="{{'contenidoDashboard.facturacion.informacionTributaria.formulario.campo2placeholder' | translate}}"></p-dropdown>
                      <div class="text-danger" style="font-size: 14px;" *ngIf="informacionTributaria.get('codigoFiscal').hasError('required') && (informacionTributaria.get('codigoFiscal').touched)">
                        {{'contenidoDashboard.facturacion.informacionTributaria.formulario.alert' | translate}}
                      </div>
                  </div>
                  <div class="payment-form">
                    <label for="numero-identificacion"><b>{{'contenidoDashboard.facturacion.informacionTributaria.formulario.campo4' | translate}}</b></label>
                    <input type="text" id="numero-identificacion" class="form-control" placeholder="{{'contenidoDashboard.facturacion.informacionTributaria.formulario.campo4placeholder' | translate}}"
                      formControlName="numeroIdentificacion" pattern="[0-9]*">
                      <div class="text-danger" style="font-size: 14px;"
                            *ngIf="informacionTributaria.get('numeroIdentificacion').invalid && (informacionTributaria.get('numeroIdentificacion').dirty || informacionTributaria.get('numeroIdentificacion').touched)">
                            <ng-container *ngIf="informacionTributaria.get('numeroIdentificacion').errors['pattern']">
                               {{'contenidoDashboard.facturacion.informacionTributaria.formulario.alertas.identiModal' | translate}}
                            </ng-container>
                            <ng-container *ngIf="informacionTributaria.get('numeroIdentificacion').errors['minlength']">
                               {{'contenidoDashboard.facturacion.informacionTributaria.formulario.alertas.noCumpleCaracteres' | translate}}
                            </ng-container>
                            <ng-container *ngIf="informacionTributaria.get('numeroIdentificacion').errors['maxlength']">
                              {{'contenidoDashboard.facturacion.metododepago.alertas.maximoCaracteres1' | translate}} 20 {{'contenidoDashboard.facturacion.metododepago.alertas.caracteres' | translate}}
                            </ng-container>
                            <ng-container *ngIf="informacionTributaria.get('numeroIdentificacion').hasError('required')">
                              {{'contenidoDashboard.facturacion.informacionTributaria.formulario.alert' | translate}}
                            </ng-container>
                          </div>
                      <!-- <div class="error-message" *ngIf="informacionTributaria.get('numeroIdentificacion').hasError('required') && (informacionTributaria.get('numeroIdentificacion').touched)">
                        {{'contenidoDashboard.facturacion.informacionTributaria.formulario.alert' | translate}}
                      </div>
                      <div class="error-message" *ngIf="informacionTributaria.get('numeroIdentificacion').hasError('maxlength') && informacionTributaria.get('direccion').touched">
                        {{'contenidoDashboard.facturacion.informacionTributaria.formulario.alert6' | translate}}
                      </div> -->
                  </div>

                  <div>
                    <div class="city-dropdown-container">
                      <label for="ciudad"><b>{{'contenidoDashboard.facturacion.informacionTributaria.formulario.campo6' | translate}}</b></label>
                      <p-dropdown (onFilter)="onCiudadInputChange($event)" [options]="ciudades" formControlName="ciudad" optionLabel="cityName" id="ciudad" name="ciudad" [filter]="true" filterBy="cityName" [showClear]="false" (onChange)="selectCity($event.value)" placeholder="{{'contenidoDashboard.facturacion.informacionTributaria.formulario.campo6placeholder' | translate}}"  [style]="{'width':'100%'}" >
                        <ng-template pTemplate="selectedItem">
                            <div class="flex align-items-center gap-2" *ngIf="selectedCity">
                                <div>{{ selectedCity.cityName }} - {{ selectedCity.stateName }}</div>
                            </div>
                        </ng-template>
                        <ng-template let-ciudad pTemplate="item">
                            <div class="flex align-items-center gap-2">
                                <div>{{ ciudad.cityName }} - {{ ciudad.stateName }}</div>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="empty">
                          <p>{{'contenidoDashboard.facturacion.informacionTributaria.formulario.campo6empty' | translate}}</p>
                        </ng-template>
                      </p-dropdown >
                      <div class="text-danger" style="font-size: 14px;"
                      *ngIf="informacionTributaria.get('ciudad').hasError('required') && (informacionTributaria.get('ciudad').touched)">
                        {{'contenidoDashboard.facturacion.informacionTributaria.formulario.alert' | translate}}
                    </div>
                    </div>
                  </div>
                  
                  
                  <div class="payment-form">
                    <label for="apellido-contacto"><b>{{'contenidoDashboard.facturacion.informacionTributaria.formulario.campo8' | translate}}</b></label>
                    <input type="text" id="apellido-contacto" class="form-control" placeholder="{{'contenidoDashboard.facturacion.informacionTributaria.formulario.campo8placeholder' | translate}}"
                      formControlName="apellidoContacto">
                      <div class="text-danger" style="font-size: 14px;"
                      *ngIf="informacionTributaria.get('apellidoContacto').invalid && (informacionTributaria.get('apellidoContacto').dirty || informacionTributaria.get('apellidoContacto').touched)">
                      <ng-container *ngIf="informacionTributaria.get('apellidoContacto').errors['pattern']">
                        {{'contenidoDashboard.facturacion.informacionTributaria.formulario.alertas.apellido' | translate}}
                      </ng-container>
                      <ng-container *ngIf="informacionTributaria.get('apellidoContacto').errors['minlength']">
                        {{'contenidoDashboard.facturacion.informacionTributaria.formulario.alertas.noCumpleCaracteres' | translate}}
                      </ng-container>
                      <ng-container *ngIf="informacionTributaria.get('apellidoContacto').errors['maxlength']">
                        {{'contenidoDashboard.facturacion.metododepago.alertas.maximoCaracteres1' | translate}} 20 {{'contenidoDashboard.facturacion.metododepago.alertas.caracteres' | translate}}
                      </ng-container>
                      <ng-container *ngIf="informacionTributaria.get('apellidoContacto').hasError('required')">
                        {{'contenidoDashboard.facturacion.informacionTributaria.formulario.alert' | translate}}
                      </ng-container>
                    </div>
                      <!-- <div class="error-message" *ngIf="informacionTributaria.get('apellidoContacto').hasError('required') && (informacionTributaria.get('apellidoContacto').touched)">
                        {{'contenidoDashboard.facturacion.informacionTributaria.formulario.alert' | translate}}
                      </div>
                      <div class="error-message" *ngIf="informacionTributaria.get('apellidoContacto').hasError('maxlength') && informacionTributaria.get('direccion').touched">
                        {{'contenidoDashboard.facturacion.informacionTributaria.formulario.alert7' | translate}}
                      </div> -->
                  </div>
                  <div class="payment-form">
                    <label for="telefono-factura"><b>{{'contenidoDashboard.facturacion.informacionTributaria.formulario.campo10' | translate}}</b></label>
                    <input type="text" id="telefono-factura" class="form-control" placeholder="{{'contenidoDashboard.facturacion.informacionTributaria.formulario.campo10placeholder' | translate}}"
                      formControlName="telefonoFactura" pattern="[0-9]*">
                      <!-- <div class="error-message" *ngIf="informacionTributaria.get('telefonoFactura').invalid && (informacionTributaria.get('telefonoFactura').touched)">
                        <div *ngIf="informacionTributaria.get('telefonoFactura').hasError('required')">{{'contenidoDashboard.facturacion.informacionTributaria.formulario.alert' | translate}}</div>
                        <div *ngIf="informacionTributaria.get('telefonoFactura').hasError('pattern')">*El teléfono debe contener solo números.</div>
                        <div *ngIf="informacionTributaria.get('telefonoFactura').hasError('maxlength')">*El teléfono no debe superar los 10 caracteres.</div>
                      </div> -->
                      <div class="text-danger" style="font-size: 14px;"
                            *ngIf="informacionTributaria.get('telefonoFactura').invalid && (informacionTributaria.get('telefonoFactura').dirty || informacionTributaria.get('telefonoFactura').touched)">
                            <ng-container *ngIf="informacionTributaria.get('telefonoFactura').errors['pattern']">
                              {{'contenidoDashboard.facturacion.informacionTributaria.formulario.alertas.telefono' | translate}}
                            </ng-container>
                            <ng-container *ngIf="informacionTributaria.get('telefonoFactura').errors['minlength']">
                              {{'contenidoDashboard.facturacion.informacionTributaria.formulario.alertas.noCumpleCaracteres' | translate}}
                            </ng-container>
                            <ng-container *ngIf="informacionTributaria.get('telefonoFactura').errors['maxlength']">
                              {{'contenidoDashboard.facturacion.metododepago.alertas.maximoCaracteres1' | translate}} 20 {{'contenidoDashboard.facturacion.metododepago.alertas.caracteres' | translate}}
                            </ng-container>
                            <ng-container *ngIf="informacionTributaria.get('telefonoFactura').hasError('required')">
                              {{'contenidoDashboard.facturacion.informacionTributaria.formulario.alert' | translate}}
                            </ng-container>
                          </div>
                  </div>
                </div>
              </div>
              <div class="d-flex gap-2">
                <p-checkbox [binary]="true" formControlName="iva" id="iva"></p-checkbox>
                <label for="iva" (click)="toggleCheckbox()">{{'contenidoDashboard.facturacion.informacionTributaria.formulario.campo11' | translate}}</label>
              </div>
              <hr class="line-unavailable2 line-unavailable">
              <div class="d-flex justify-content-end" style="margin-right: -15%;">
                <button class="button-primary-dashboard" [disabled]="informacionTributaria.invalid">{{'contenidoDashboard.facturacion.informacionTributaria.formulario.btonGuardar' | translate}}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
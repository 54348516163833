import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Ciudad } from 'src/app/models/facturacion/ciudad.model'

@Injectable({
  providedIn: 'root'
})
export class CiudadService {
  private urlCiudades = environment.urlCiudades;

  constructor(private http: HttpClient) { }

  searchCiudades(ciudad: string): Observable<Ciudad[]> {
  
    return this.http.get<Ciudad[]>(`${this.urlCiudades}?ciudad=${ciudad}`);
  }
}
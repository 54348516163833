import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SuscripcionesActivas } from 'src/app/models/facturacion/obtener-suscripciones-activas.model';

@Injectable({
  providedIn: 'root'
})
export class ObtenerSuscripcionesActivasService {

  private urlObtenerSuscripcionesActivas: string = environment.urlObtenerSuscripcionesActivas;

  constructor(private http: HttpClient) { }

  obtenerSuscripcionesActivas(tenant: string): Observable<SuscripcionesActivas> {
    return this.http.get<SuscripcionesActivas>(this.urlObtenerSuscripcionesActivas + tenant + '/activas' + '?order=asc');
  }
}

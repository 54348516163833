import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { SubscriptionsGuardService } from './services/dashboard/subscriptionsGuard.service';
import { PermisosGuard } from './guards/permisos.guard';
import { SubscriptionKeyInterceptor } from './interceptors/subscription-key-interceptor.interceptor';

import {
  IPublicClientApplication,
  PublicClientApplication,
  InteractionType
} from '@azure/msal-browser';

import {
  MsalGuard,
  MsalBroadcastService,
  MsalModule,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MsalGuardConfiguration,
  MsalRedirectComponent,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MSAL_INTERCEPTOR_CONFIG
} from '@azure/msal-angular';

import { msalConfig } from './auth-config';

//Components
import { AppComponent } from './app.component';
import { TutorialComponent } from './tutorial.components';
import { MainComponent } from './components/main/main.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';

//Modules
import { SharedModule } from './components/shared/shared.module';

//PrimeNG Modules
import { CarouselModule } from 'primeng/carousel';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { SliderModule } from 'primeng/slider';
import { InputTextModule } from 'primeng/inputtext';
import { PasswordModule } from 'primeng/password';
import { DividerModule } from 'primeng/divider';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TableModule } from 'primeng/table';
import { SkeletonModule } from 'primeng/skeleton';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { TabViewModule } from 'primeng/tabview';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { BreadcrumbModule } from 'primeng/breadcrumb';

//Angular Material Modules
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';

import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';

//Service carousel
import { CarouselService } from './services/carousel/carousel.service';

/*Fix Refresh 404*/
import { LocationStrategy, PathLocationStrategy } from '@angular/common';

//NGX-BOOTSTRAP
import { ModalModule } from 'ngx-bootstrap/modal';

/*NGX TRANSLATE*/
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModalSeleccionarPlanComponent } from './components/dashboard/shared-dashboard/modal-seleccionar-plan/modal-seleccionar-plan.component';
/**
 * Here we pass the configuration parameters to create an MSAL instance.
 * For more info, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/configuration.md
 */
export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfig);
}

export function MsalInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set('https://graph.microsoft.com/v1/me', ['https://natisaas.onmicrosoft.com/c2d47157-26fb-41da-9d54-39355f077001/Api.Read']);

  return {
    interactionType: InteractionType.Popup,
    protectedResourceMap
  }
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['https://natisaas.onmicrosoft.com/c2d47157-26fb-41da-9d54-39355f077001/Api.Read'],
    },
    loginFailedRoute: '/login-failed'
  };
}

@NgModule({
  declarations: [
    AppComponent,
    TutorialComponent,
    MainComponent,
    DashboardComponent,
    ModalSeleccionarPlanComponent
  ],
  imports: [
    MsalModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,

    //PrimeNG Modules
    CarouselModule,
    ButtonModule,
    DialogModule,
    SliderModule,
    InputTextModule,
    PasswordModule,
    DividerModule,
    ProgressSpinnerModule,
    TableModule,
    CheckboxModule,
    SkeletonModule,
    DropdownModule,
    TabViewModule,
    AutoCompleteModule,
    BreadcrumbModule,

    //NGX-BOOTSTRAP
    ModalModule.forRoot(),
    // AccordionModule.forRoot(),

    //Angular Material Modules
    MatProgressBarModule,
    MatTableModule,
    MatInputModule,
    MatCheckboxModule,
    MatButtonModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDialogModule,

    //NGX TRANSLATE
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      },
      isolate: false
    }),

    SharedModule
  ],
  providers: [
    CarouselService,
    HttpClientModule,
    SubscriptionsGuardService,
    PermisosGuard,
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
    { provide: LocationStrategy, useClass: PathLocationStrategy},
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MsalInterceptorConfigFactory
    },
    { provide: HTTP_INTERCEPTORS, 
      useClass: SubscriptionKeyInterceptor, 
      multi: true 
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }

// AOT compilation support
export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

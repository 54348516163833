<section>
  <div class="container-fluid section1">
    <div class="mxfhd">
      <div class="row">
        <div class="col-lg d-block m-auto px-5">
          <div data-aos="fade-right" data-aos-duration="1500">
            <h1 class="mt-5 mt-md-4 text-center title-main">{{'contenidoMain.section1.titulo' | translate}}</h1>
            <p class="my-3 my-md-4 text-center text-main">{{'contenidoMain.section1.texto' | translate}}</p>

            <div class="d-block m-auto text-center">
              <button class="button-primary shadow-sm mobile-button"
                (click)="openModalPlanFree(templatePlansFree, 'prueba-gratis', 'web')">{{'contenidoMain.section1.boton2'
                | translate}}</button>
            </div>
          </div>
        </div>
        <div class="col-lg d-block m-auto" data-aos="fade-left" data-aos-duration="1500"
          style="width: 90%; overflow-y: hidden;">
          <video id="video-nati" autoplay muted loop [controls]="false" [disablePictureInPicture]="true">
            <source src="../../../assets/images/landing/Nuevo-render.mp4" type="video/mp4">
          </video>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid section2 pb-5">
    <div class="mxfhd">
      <h2 class="text-center title-main pt-5 px-5">{{'contenidoMain.section2.titulo' | translate}}</h2>

      <div class="container text-center py-5 container-cards">
        <div
          class="row row-cols-1 row-cols-md-2 row-cols-xl-3 g-2 g-md-3 g-xl-4 align-items-center justify-content-center"
          data-aos="fade-up" data-aos-duration="1500">
          <div class="col" *ngFor="let item of carousel">
            <div class="cardHover d-block m-auto">
              <div class="card shadow-sm">
                <img class="imgsCards" alt="Carousel Image" src="{{item.imagen}}">
                <div class="card-body textCards">
                  <p class="">{{item.titleOne}}</p>
                </div>
              </div>

              <div class="card-body shadow-sm textCardsHover cardBack" style="text-align: center; padding: 5%;">
                <p class="">{{item.textCardBack}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid section3">
    <div class="mxfhd">
      <h2 class="text-center title-main pt-5">{{'contenidoMain.section3.titulo' | translate}}</h2>

      <div class="d-flex flex-wrap gap-5 py-3 justify-content-center">
        <video id="video-generacion" muted loop autoplay [controls]="true" [disablePictureInPicture]="true">
          <source src="../../../assets/images/landing/GeneracionMedico.mp4" type="video/mp4">
        </video>

        <video id="video-charla" muted loop autoplay [controls]="true" [disablePictureInPicture]="true">
          <source src="../../../assets/images/landing/CharlaMedico.mp4" type="video/mp4">
        </video>
      </div>

      <p class="text-center text-main">{{'contenidoMain.section3.texto' | translate}}</p>
    </div>
  </div>

  <div class="container-fluid section4">
    <div class="mxfhd">
      <div class="col-lg d-block text-center m-auto p-2 p-md-5" data-aos="zoom-in-down" data-aos-duration="1500">
        <h2 class="mt-5 mt-md-4 title-main">{{'contenidoMain.section4.titulo' | translate}}</h2>
        <p class="mb-3 my-md-4 text-main">{{'contenidoMain.section4.texto' | translate}}</p>

        <div class="mx-fhd mt-3">
          <p-carousel [value]="carouselUseCases" [numVisible]="4" [numScroll]="1" [circular]="false"
            [responsiveOptions]="responsiveOptions" [showNavigators]="true" [showIndicators]="true">
            <ng-template let-carouselBlog pTemplate="item">
              <div class="card carousel-usecases shadow-sm position-relative">
                <img src="{{carouselBlog.imagen}}" class="card-img-top" alt="Blog">
                <div class="title-usecase">
                  <h5>{{carouselBlog.title}}</h5>
                </div>
              </div>
            </ng-template>
          </p-carousel>
        </div>

        <p class="mt-5 text-main">{{'contenidoMain.section4.texto2' | translate}}</p>

        <div class="m-auto">
          <button class="shadow button-primary" (click)="openModalPlanFree(templatePlansFree, 'prueba-gratis', 'web')">{{'contenidoMain.section1.boton2' | translate}}</button>
        </div>

        <p class="mt-4" style="color: #706F6F; font-size: 20px;">{{'contenidoMain.section4.texto3' | translate}}</p>
      </div>
    </div>
  </div>

  <div class="container-fluid section7">
    <div class="mxfhd">
      <div class="row pb-5 py-md-5" data-aos="fade-up" data-aos-duration="1500">
        <div class="col-lg m-auto px-5 text-center justify-content-center d-grid">
          <h2 class="mt-5 mt-md-4 title-main">{{'contenidoMain.sectionDisponible.titulo' | translate}}</h2>
          <p class="mb-4 my-md-4 text-main">{{'contenidoMain.sectionDisponible.texto' | translate}}</p>

          <div class="d-flex flex-wrap gap-4 justify-content-center">
            <button class="shadow-sm button-secondary"
              (click)="showSection('web')">{{'contenidoMain.sectionDisponible.web' | translate}}</button>
            <button class="shadow-sm button-secondary"
              (click)="showSection('kiosco')">{{'contenidoMain.sectionDisponible.kiosco' | translate}}</button>
            <button class="shadow-sm button-secondary"
              (click)="showSection('whatsapp')">{{'contenidoMain.sectionDisponible.whatsapp' | translate}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid section-plans" id="section-plans">
    <!-- Modal plan seleccionado gratis -->
    <ng-template #templatePlansFree>
      <div class="modal-body" style="display: grid;">
        <button type="button" class="btn-close close pull-right"
          style="justify-self: flex-end; width: 7px; height: 7px;" aria-label="Close" (click)="modalPlans?.hide()">
          <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
        <div class="register-container">
          <div class="row m-0 row-container">
            <div class="col d-block m-auto">
              <div class="w-100 bg-nati-avatar">
                <img src="../../../assets/images/nati-new-logo.png" class="position-absolute top-0 m-3" alt="Nati"
                  width="155">

                <div class="text-center">
                  <img src="../../../assets/images/landing/nati.png" alt="Nati avatar" class="img-fluid avatar">
                </div>

                <div class="blur-container">
                  <div class="blur-text">
                    <h5>{{'contenidoMain.modales.textImg1' | translate}} <br><b>{{'contenidoMain.modales.textImg2' |
                        translate}} </b></h5>
                    <p class="mt-3">{{'contenidoMain.modales.textImgSubtitulo' | translate}} </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="col">
              <div class="w-100 d-block m-auto text-center">
                <h4 class="text-center fw-bold">{{'contenidoMain.modales.modalPruebaGratis.titulo' | translate}}</h4>

                <hr class="line-unavailable">

                <p class="service-unavailable-text">{{'contenidoMain.modales.modalPruebaGratis.texto1' | translate}}
                  <br> {{'contenidoMain.modales.modalPruebaGratis.texto2' | translate}}<br> </p>

                <hr class="line-unavailable">

                <button class="button-primary shadow-sm mobile-button"
                  (click)="registerWithPlan(canalSelected, planSelected, 'semanal')">{{'contenidoMain.modales.registrarse'
                  | translate}}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>

    <!-- Modal plan seleccionado de pago -->
    <ng-template #templatePlans>
      <div class="modal-body" style="display: grid;">
        <button type="button" class="btn-close close pull-right"
          style="justify-self: flex-end; width: 7px; height: 7px;" aria-label="Close" (click)="modalPlans?.hide()">
          <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
        <div class="register-container">
          <div class="row m-0 row-container">
            <div class="col d-block m-auto">
              <div class="w-100 bg-nati-avatar">
                <img src="../../../assets/images/nati-new-logo.png" class="position-absolute top-0 m-3" alt="Nati"
                  width="155">

                <div class="text-center">
                  <img src="../../../assets/images/landing/nati.png" alt="Nati avatar" class="img-fluid avatar">
                </div>

                <div class="blur-container">
                  <div class="blur-text">
                    <h5>{{'contenidoMain.modales.textImg1' | translate}} <br><b>{{'contenidoMain.modales.textImg2' |
                        translate}}</b></h5>
                    <p class="mt-3">{{'contenidoMain.modales.textImgSubtitulo' | translate}}</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="col">
              <div class="w-100 d-block m-auto text-center">
                <h4 class="text-center fw-bold">{{'contenidoMain.modales.modalAdquirirPlan.adquirir' |
                  translate}}{{planSelectedFormatted | lowercase}} /
                  {{'contenidoMain.modales.modalAdquirirPlan.canal' | translate}}
                  {{canalSelected}}</h4>

                <hr class="line-unavailable">

                <p class="service-unavailable-text">{{'contenidoMain.modales.modalAdquirirPlan.texto' | translate}}
                  <b>{{planSelectedFormatted | lowercase}}</b>
                  {{'contenidoMain.modales.modalAdquirirPlan.texto2' | translate}}</p>

                <hr class="line-unavailable">

                <button class="button-primary shadow-sm mobile-button"
                  (click)="registerWithPlan(canalSelected, planSelected, frecuenciaSelected)">{{'contenidoMain.modales.registrarse'
                  | translate}}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>

    <!-- Modal plan seleccionado de pago anual -->
    <ng-template #templatePlanAnual>
      <div class="modal-body" style="display: grid;">
        <button type="button" class="btn-close close pull-right"
          style="justify-self: flex-end; width: 7px; height: 7px;" aria-label="Close" (click)="modalPlanAnual?.hide()">
          <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
        <div class="register-container">
          <div class="row m-0 row-container">
            <div class="col d-block m-auto">
              <div class="w-100 bg-nati-avatar">
                <img src="../../../assets/images/nati-new-logo.png" class="position-absolute top-0 m-3" alt="Nati"
                  width="155">

                <div class="text-center">
                  <img src="../../../assets/images/landing/nati.png" alt="Nati avatar" class="img-fluid avatar">
                </div>

                <div class="blur-container">
                  <div class="blur-text">
                    <h5>{{'contenidoMain.modales.textImg1' | translate}} <br><b>{{'contenidoMain.modales.textImg2' |
                        translate}} </b></h5>
                    <p class="mt-3">{{'contenidoMain.modales.textImgSubtitulo' | translate}}</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="col">
              <div class="w-100 d-block m-auto text-center">
                <h4 class="text-center fw-bold">{{'contenidoMain.modales.modalAdquirirPlan.adquirir' | translate}}
                  {{planSelectedAnualFormatted | lowercase}} {{'contenidoMain.modales.modalAdquirirPlan.formaAnual' |
                  translate}} {{canalSelectedAnual}}</h4>

                <hr class="line-unavailable">

                <p class="service-unavailable-text">{{'contenidoMain.modales.modalAdquirirPlan.texto' |
                  translate}}<b>{{'contenidoMain.modales.modalAdquirirPlan.plan' | translate}}
                    {{planSelectedAnualFormatted | lowercase}}
                    {{'contenidoMain.modales.modalAdquirirPlan.anual' | translate}}</b>
                  {{'contenidoMain.modales.modalAdquirirPlan.texto2' | translate}}</p>

                <hr class="line-unavailable">

                <button class="button-primary shadow-sm mobile-button"
                  (click)="registerWithPlan(canalSelectedAnual, planSelectedAnual, frecuenciaSelectedAnual)">{{'contenidoMain.modales.registrarse'
                  | translate}}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>

    <div class="d-block m-auto my-5" *ngIf="showSectionWeb || showSectionKiosco || showSectionWhatsapp">

      <div class="w-100 d-block m-auto text-center">
        <!-- Planes canal Web -->
        <div *ngIf="showSectionWeb">
          <h2 class="fw-bold mt-5 mb-3 mt-md-4 text-center title-main">{{'contenidoMain.sectionPlanes.tituloWeb' |
            translate}}</h2>
          <p class="text-main mb-4">{{'contenidoMain.sectionPlanes.textoWeb' | translate}}</p>

          <div class="w-100 d-block m-auto text-center">
            <div id="tabview-frequency-suscription" class="">
              <p-tabView>
                <p-tabPanel header="{{'contenidoMain.sectionPlanes.mensual' | translate}}">
                  <div class="d-flex flex-wrap gap-3 justify-content-center align-items-center my-5">
                    <!-- Plan Basico -->
                    <div class="plan pBasic">
                      <div class="titlePlans titlePlanBasic">
                        <span>{{'contenidoMain.sectionPlanes.basico' | translate}}</span>
                      </div>
                      <div class="container">
                        <p class="text-center pricePlan"><b>USD
                            ${{formatPrecio(planesCanalWebMensual[0]?.precioPlan)}}</b>/{{'contenidoDashboard.suscripcion.planesInfo.mes'
                          |
                          translate}}</p>
                        <hr class="bg-dark w-100 m-auto" style="height: 2px" />

                        <div class="info-plan">
                          <p class="mt-2">{{'contenidoMain.sectionPlanes.incluye' | translate}}
                            {{formatPrecio(planesCanalWebMensual[0]?.cantidadIncluida)}}
                            {{'contenidoMain.sectionPlanes.mensajes' | translate}}</p>
                          <p>{{'contenidoMain.sectionPlanes.mensajeAdicional' | translate}} USD
                            ${{planesCanalWebMensual[0]?.precioUnidadAdicional}}</p>
                          <p>{{'contenidoMain.sectionPlanes.almacenamiento' | translate}}
                            {{planesCanalWebMensual[0]?.capacidadAlmacenamiento}}MB</p>
                        </div>

                        <button type="button" class="shadow-sm button-basic-outline"
                          (click)="openModalPlans(templatePlans, 'basico', 'web', 'mensual')">
                          {{'contenidoMain.sectionPlanes.textoBoton' | translate}}
                        </button>
                      </div>
                    </div>

                    <!-- Plan Estandar -->
                    <div class="plan pAdvanced">
                      <div class="titlePlans titlePlanAdvanced">
                        <span>{{'contenidoMain.sectionPlanes.estandar' | translate}}</span>
                      </div>
                      <div class="container">
                        <p class="text-center pricePlan"><b>USD
                            ${{formatPrecio(planesCanalWebMensual[1]?.precioPlan)}}</b>/{{'contenidoDashboard.suscripcion.planesInfo.mes'
                          |
                          translate}}</p>
                        <hr class="bg-dark w-100 m-auto" style="height: 2px" />

                        <div class="info-plan">
                          <p class="mt-2">{{'contenidoMain.sectionPlanes.incluye' | translate}}
                            {{formatPrecio(planesCanalWebMensual[1]?.cantidadIncluida)}}
                            {{'contenidoMain.sectionPlanes.mensajes' | translate}}</p>
                          <p>{{'contenidoMain.sectionPlanes.mensajeAdicional' | translate}} USD
                            ${{planesCanalWebMensual[1]?.precioUnidadAdicional}}</p>
                          <p>{{'contenidoMain.sectionPlanes.almacenamiento' | translate}}
                            {{planesCanalWebMensual[1]?.capacidadAlmacenamiento}}MB</p>
                        </div>

                        <button type="button" class="shadow-sm button-advanced-outline"
                          (click)="openModalPlans(templatePlans, 'estandar', 'web', 'mensual')">
                          {{'contenidoMain.sectionPlanes.textoBoton' | translate}}
                        </button>
                      </div>
                    </div>

                    <!-- Plan Premium -->
                    <div class="plan pEnterprise">
                      <div class="titlePlans titlePlanEnterprise">
                        <span>{{'contenidoMain.sectionPlanes.premium' | translate}}</span>
                      </div>
                      <div class="container">
                        <p class="text-center pricePlan"><b>USD
                            ${{formatPrecio(planesCanalWebMensual[2]?.precioPlan)}}</b>/{{'contenidoDashboard.suscripcion.planesInfo.mes'
                          |
                          translate}}</p>
                        <hr class="bg-dark w-100 m-auto" style="height: 2px" />

                        <div class="info-plan">
                          <p class="mt-2">{{'contenidoMain.sectionPlanes.incluye' | translate}}
                            {{formatPrecio(planesCanalWebMensual[2]?.cantidadIncluida)}}
                            {{'contenidoMain.sectionPlanes.mensajes' | translate}}</p>
                          <p>{{'contenidoMain.sectionPlanes.mensajeAdicional' | translate}} USD
                            ${{planesCanalWebMensual[2]?.precioUnidadAdicional}}</p>
                          <p>{{'contenidoMain.sectionPlanes.almacenamiento' | translate}}
                            {{planesCanalWebMensual[2]?.capacidadAlmacenamiento}}MB</p>
                        </div>

                        <button type="button" class="shadow-sm button-enterprise-outline"
                          (click)="openModalPlans(templatePlans, 'premium', 'web', 'mensual')">
                          {{'contenidoMain.sectionPlanes.textoBoton' | translate}}
                        </button>
                      </div>
                    </div>
                  </div>
                </p-tabPanel>
                <p-tabPanel header="{{'contenidoMain.sectionPlanes.anual' | translate}}">
                  <div class="d-flex flex-wrap gap-3 justify-content-center align-items-center my-5">
                    <!-- Plan Basico -->
                    <div class="plan pBasic" style="height: 30rem !important;">
                      <div class="titlePlans titlePlanBasic">
                        <span>{{'contenidoMain.sectionPlanes.basico' | translate}}</span>
                      </div>
                      <div class="container">
                        <p class="text-center pricePlan"><b>USD
                            ${{formatPrecio(planesCanalWebAnual[0]?.precioPlan)}}</b>/{{'contenidoDashboard.suscripcion.planesInfo.anual'
                          |
                          translate}}</p>
                        <hr class="bg-dark w-100 m-auto" style="height: 2px" />

                        <div class="info-plan" style="display: grid;">
                          <p class="mt-2">{{'contenidoMain.sectionPlanes.incluye' | translate}}
                            {{formatPrecio(planesCanalWebAnual[0]?.cantidadIncluida)}}
                            {{'contenidoMain.sectionPlanes.mensajes' | translate}}</p>
                          <p>{{'contenidoMain.sectionPlanes.descuento' | translate}}</p>
                          <p>{{'contenidoMain.sectionPlanes.valorMensual' | translate}} USD ${{valorMensualBasicoWeb}}
                          </p>
                          <p>{{'contenidoMain.sectionPlanes.almacenamiento' | translate}}
                            {{planesCanalWebAnual[0]?.capacidadAlmacenamiento}}MB</p>
                        </div>

                        <button type="button" class="shadow-sm button-basic-outline"
                          (click)="openModalPlanAnual(templatePlanAnual, 'basico', 'web', 'anual')">
                          {{'contenidoMain.sectionPlanes.textoBoton' | translate}}
                        </button>
                      </div>
                    </div>

                    <!-- Plan Estandar -->
                    <div class="plan pAdvanced" style="height: 30rem !important;">
                      <div class="titlePlans titlePlanAdvanced">
                        <span>{{'contenidoMain.sectionPlanes.estandar' | translate}}</span>
                      </div>
                      <div class="container">
                        <p class="text-center pricePlan"><b>USD
                            ${{formatPrecio(planesCanalWebAnual[1]?.precioPlan)}}</b>/{{'contenidoDashboard.suscripcion.planesInfo.anual'
                          |
                          translate}}</p>
                        <hr class="bg-dark w-100 m-auto" style="height: 2px" />

                        <div class="info-plan" style="display: grid;">
                          <p class="mt-2">{{'contenidoMain.sectionPlanes.incluye' | translate}}
                            {{formatPrecio(planesCanalWebAnual[1]?.cantidadIncluida)}}
                            {{'contenidoMain.sectionPlanes.mensajes' | translate}}</p>
                          <p>{{'contenidoMain.sectionPlanes.descuento' | translate}}</p>
                          <p>{{'contenidoMain.sectionPlanes.valorMensual' | translate}} USD ${{valorMensualEstandarWeb}}
                          </p>
                          <p>{{'contenidoMain.sectionPlanes.almacenamiento' | translate}}
                            {{planesCanalWebAnual[1]?.capacidadAlmacenamiento}}MB</p>
                        </div>

                        <button type="button" class="shadow-sm button-advanced-outline"
                          (click)="openModalPlanAnual(templatePlanAnual, 'estandar', 'web', 'anual')">
                          {{'contenidoMain.sectionPlanes.textoBoton' | translate}}
                        </button>
                      </div>
                    </div>

                    <!-- Plan Premium -->
                    <div class="plan pEnterprise" style="height: 30rem !important;">
                      <div class="titlePlans titlePlanEnterprise">
                        <span>{{'contenidoMain.sectionPlanes.premium' | translate}}</span>
                      </div>
                      <div class="container">
                        <p class="text-center pricePlan"><b>USD
                            ${{formatPrecio(planesCanalWebAnual[2]?.precioPlan)}}</b>/{{'contenidoDashboard.suscripcion.planesInfo.anual'
                          |
                          translate}}</p>
                        <hr class="bg-dark w-100 m-auto" style="height: 2px" />

                        <div class="info-plan" style="display: grid;">
                          <p class="mt-2">{{'contenidoMain.sectionPlanes.incluye' | translate}}
                            {{formatPrecio(planesCanalWebAnual[2]?.cantidadIncluida)}}
                            {{'contenidoMain.sectionPlanes.mensajes' | translate}}</p>
                          <p>{{'contenidoMain.sectionPlanes.descuento' | translate}}</p>
                          <p>{{'contenidoMain.sectionPlanes.valorMensual' | translate}} USD ${{valorMensualPremiumWeb}}
                          </p>
                          <p>{{'contenidoMain.sectionPlanes.almacenamiento' | translate}}
                            {{planesCanalWebAnual[2]?.capacidadAlmacenamiento}}MB</p>
                        </div>

                        <button type="button" class="shadow-sm button-enterprise-outline"
                          (click)="openModalPlanAnual(templatePlanAnual, 'premium', 'web', 'anual')">
                          {{'contenidoMain.sectionPlanes.textoBoton' | translate}}
                        </button>
                      </div>
                    </div>
                  </div>
                </p-tabPanel>
              </p-tabView>
            </div>
          </div>
        </div>
        <!-- Planes canal Web -->

        <!-- Planes canal Kiosco -->
        <div *ngIf="showSectionKiosco">
          <h2 class="mt-5 mb-4 mt-md-4 text-center title-main">{{'contenidoMain.sectionPlanes.tituloKiosco' |
            translate}}</h2>
          <p class="text-main mb-4">{{'contenidoMain.sectionPlanes.textoKiosco' | translate}}</p>

          <div class="w-100 d-block m-auto text-center">
            <div id="tabview-frequency-suscription" class="">
              <p-tabView>
                <p-tabPanel header="{{'contenidoMain.sectionPlanes.mensual' | translate}}">
                  <div class="d-flex flex-wrap gap-3 justify-content-center align-items-center my-5">
                    <!-- Plan Basico -->
                    <div class="plan pBasic" style="height: 29rem !important;">
                      <div class="titlePlans titlePlanBasic">
                        <span>{{'contenidoMain.sectionPlanes.basico' | translate}}</span>
                      </div>
                      <div class="container">
                        <p class="text-center pricePlan"><b>USD
                            ${{formatPrecio(planesCanalKioscoMensual[0]?.precioPlan)}}</b>/{{'contenidoDashboard.suscripcion.planesInfo.mes'
                          |
                          translate}}</p>
                        <hr class="bg-dark w-100 m-auto" style="height: 2px" />

                        <div class="info-plan">
                          <p class="mt-2">{{'contenidoMain.sectionPlanes.incluye' | translate}}
                            {{formatPrecio(planesCanalKioscoMensual[0]?.cantidadIncluida)}}
                            {{'contenidoMain.sectionPlanes.kiosco' | translate}}</p>
                          <p>{{'contenidoMain.sectionPlanes.precioKiosco' | translate}} USD $242</p>
                          <p>{{'contenidoMain.sectionPlanes.mensajeIlimitado' | translate}} </p>
                          <p>{{'contenidoMain.sectionPlanes.almacenamiento' | translate}}
                            {{planesCanalKioscoMensual[0]?.capacidadAlmacenamiento}}MB</p>
                        </div>

                        <button type="button" class="shadow-sm button-basic-outline"
                          (click)="openModalPlans(templatePlans, 'basico', 'kiosco', 'mensual')">
                          {{'contenidoMain.sectionPlanes.textoBoton' | translate}}
                        </button>
                      </div>
                    </div>

                    <!-- Plan Estandar -->
                    <div class="plan pAdvanced" style="height: 29rem !important;">
                      <div class="titlePlans titlePlanAdvanced">
                        <span>{{'contenidoMain.sectionPlanes.estandar' | translate}}</span>
                      </div>
                      <div class="container">
                        <p class="text-center pricePlan"><b>USD
                            ${{formatPrecio(planesCanalKioscoMensual[1]?.precioPlan)}}</b>/{{'contenidoDashboard.suscripcion.planesInfo.mes'
                          |
                          translate}}</p>
                        <hr class="bg-dark w-100 m-auto" style="height: 2px" />

                        <div class="info-plan">
                          <p class="mt-2">{{'contenidoMain.sectionPlanes.incluye' | translate}}
                            {{formatPrecio(planesCanalKioscoMensual[1]?.cantidadIncluida)}}
                            {{'contenidoMain.sectionPlanes.kiosco' | translate}}</p>
                          <p>{{'contenidoMain.sectionPlanes.precioKiosco' | translate}} USD $206</p>
                          <p>{{'contenidoMain.sectionPlanes.mensajeIlimitado' | translate}}</p>
                          <p>{{'contenidoMain.sectionPlanes.almacenamiento' | translate}}
                            {{planesCanalKioscoMensual[1]?.capacidadAlmacenamiento}}MB</p>
                        </div>

                        <button type="button" class="shadow-sm button-advanced-outline"
                          (click)="openModalPlans(templatePlans, 'estandar', 'kiosco', 'mensual')">
                          {{'contenidoMain.sectionPlanes.textoBoton' | translate}}
                        </button>
                      </div>
                    </div>

                    <!-- Plan Premium -->
                    <div class="plan pEnterprise" style="height: 29rem !important;">
                      <div class="titlePlans titlePlanEnterprise">
                        <span>{{'contenidoMain.sectionPlanes.premium' | translate}}</span>
                      </div>
                      <div class="container">
                        <p class="text-center pricePlan"><b>USD
                            ${{formatPrecio(planesCanalKioscoMensual[2]?.precioPlan)}}</b>/{{'contenidoDashboard.suscripcion.planesInfo.mes'
                          |
                          translate}}</p>
                        <hr class="bg-dark w-100 m-auto" style="height: 2px" />

                        <div class="info-plan">
                          <p class="mt-2">{{'contenidoMain.sectionPlanes.incluye' | translate}}
                            {{formatPrecio(planesCanalKioscoMensual[2]?.cantidadIncluida)}}
                            {{'contenidoMain.sectionPlanes.kiosco' | translate}}</p>
                          <p>{{'contenidoMain.sectionPlanes.precioKiosco' | translate}} USD $181</p>
                          <p>{{'contenidoMain.sectionPlanes.mensajeIlimitado' | translate}}</p>
                          <p>{{'contenidoMain.sectionPlanes.almacenamiento' | translate}}
                            {{planesCanalKioscoMensual[2]?.capacidadAlmacenamiento}}MB</p>
                        </div>

                        <button type="button" class="shadow-sm button-enterprise-outline"
                          (click)="openModalPlans(templatePlans, 'premium', 'kiosco', 'mensual')">
                          {{'contenidoMain.sectionPlanes.textoBoton' | translate}}
                        </button>
                      </div>
                    </div>
                  </div>
                </p-tabPanel>
                <p-tabPanel header="{{'contenidoMain.sectionPlanes.anual' | translate}}">
                  <div class="d-flex flex-wrap gap-3 justify-content-center align-items-center my-5">
                    <!-- Plan Basico -->
                    <div class="plan pBasic" style="height: 29rem !important;">
                      <div class="titlePlans titlePlanBasic">
                        <span>{{'contenidoMain.sectionPlanes.basico' | translate}}</span>
                      </div>
                      <div class="container">
                        <p class="text-center pricePlan"><b>USD
                            ${{formatPrecio(planesCanalKioscoAnual[0]?.precioPlan)}}</b>/{{'contenidoDashboard.suscripcion.planesInfo.anual'
                          |
                          translate}}</p>
                        <hr class="bg-dark w-100 m-auto" style="height: 2px" />

                        <div class="info-plan">
                          <p class="mt-2">{{'contenidoMain.sectionPlanes.incluye' | translate}}
                            {{formatPrecio(planesCanalKioscoAnual[0]?.cantidadIncluida)}}
                            {{'contenidoMain.sectionPlanes.kiosco' | translate}}</p>
                          <p>{{'contenidoMain.sectionPlanes.precioKiosco' | translate}} USD $217,8</p>
                          <p>{{'contenidoMain.sectionPlanes.mensajeIlimitado' | translate}}</p>
                          <p>{{'contenidoMain.sectionPlanes.almacenamiento' | translate}}
                            {{planesCanalKioscoAnual[0]?.capacidadAlmacenamiento}}MB</p>
                        </div>

                        <button type="button" class="shadow-sm button-basic-outline"
                          (click)="openModalPlanAnual(templatePlanAnual, 'basico', 'kiosco', 'anual')">
                          {{'contenidoMain.sectionPlanes.textoBoton' | translate}}
                        </button>
                      </div>
                    </div>

                    <!-- Plan Estandar -->
                    <div class="plan pAdvanced" style="height: 29rem !important;">
                      <div class="titlePlans titlePlanAdvanced">
                        <span>{{'contenidoMain.sectionPlanes.estandar' | translate}}</span>
                      </div>
                      <div class="container">
                        <p class="text-center pricePlan"><b>USD
                            ${{formatPrecio(planesCanalKioscoAnual[1]?.precioPlan)}}</b>/{{'contenidoDashboard.suscripcion.planesInfo.anual'
                          |
                          translate}}</p>
                        <hr class="bg-dark w-100 m-auto" style="height: 2px" />

                        <div class="info-plan">
                          <p class="mt-2">{{'contenidoMain.sectionPlanes.incluye' | translate}}
                            {{formatPrecio(planesCanalKioscoAnual[1]?.cantidadIncluida)}}
                            {{'contenidoMain.sectionPlanes.kiosco' | translate}}</p>
                          <p>{{'contenidoMain.sectionPlanes.precioKiosco' | translate}} USD $185,4</p>
                          <p>{{'contenidoMain.sectionPlanes.mensajeIlimitado' | translate}}</p>
                          <p>{{'contenidoMain.sectionPlanes.almacenamiento' | translate}}
                            {{planesCanalKioscoAnual[1]?.capacidadAlmacenamiento}}MB</p>
                        </div>

                        <button type="button" class="shadow-sm button-advanced-outline"
                          (click)="openModalPlanAnual(templatePlanAnual, 'estandar', 'kiosco', 'anual')">
                          {{'contenidoMain.sectionPlanes.textoBoton' | translate}}
                        </button>
                      </div>
                    </div>

                    <!-- Plan Premium -->
                    <div class="plan pEnterprise" style="height: 29rem !important;">
                      <div class="titlePlans titlePlanEnterprise">
                        <span>{{'contenidoMain.sectionPlanes.premium' | translate}}</span>
                      </div>
                      <div class="container">
                        <p class="text-center pricePlan"><b>USD
                            ${{formatPrecio(planesCanalKioscoAnual[2]?.precioPlan)}}</b>/{{'contenidoDashboard.suscripcion.planesInfo.anual'
                          |
                          translate}}</p>
                        <hr class="bg-dark w-100 m-auto" style="height: 2px" />

                        <div class="info-plan">
                          <p class="mt-2">{{'contenidoMain.sectionPlanes.incluye' | translate}}
                            {{formatPrecio(planesCanalKioscoAnual[2]?.cantidadIncluida)}}
                            {{'contenidoMain.sectionPlanes.kiosco' | translate}}</p>
                          <p>{{'contenidoMain.sectionPlanes.precioKiosco' | translate}} USD $162,9</p>
                          <p>{{'contenidoMain.sectionPlanes.mensajeIlimitado' | translate}}</p>
                          <p>{{'contenidoMain.sectionPlanes.almacenamiento' | translate}}
                            {{planesCanalKioscoAnual[2]?.capacidadAlmacenamiento}}MB</p>
                        </div>

                        <button type="button" class="shadow-sm button-enterprise-outline"
                          (click)="openModalPlanAnual(templatePlanAnual, 'premium', 'kiosco', 'anual')">
                          {{'contenidoMain.sectionPlanes.textoBoton' | translate}}
                        </button>
                      </div>
                    </div>
                  </div>
                </p-tabPanel>
              </p-tabView>
            </div>
          </div>
        </div>
        <!-- Planes canal Kiosco -->

        <!-- Planes canal Whatsapp-->
        <div *ngIf="showSectionWhatsapp">
          <h2 class="mt-5 mb-4 mt-md-4 text-center title-main">{{'contenidoMain.sectionPlanes.tituloWhatsapp' |
            translate}}</h2>
          <p class="text-main mb-4">{{'contenidoMain.sectionPlanes.textoWhatsapp' | translate}}</p>

          <div class="w-100 d-block m-auto text-center">
            <div id="tabview-frequency-suscription">
              <p-tabView>
                <p-tabPanel header="{{'contenidoMain.sectionPlanes.mensual' | translate}}">
                  <div class="d-flex flex-wrap gap-3 justify-content-center align-items-center my-5">
                    <!-- Plan Basico -->
                    <div class="plan pBasic">
                      <div class="titlePlans titlePlanBasic">
                        <span>{{'contenidoMain.sectionPlanes.basico' | translate}}</span>
                      </div>
                      <div class="container">
                        <p class="text-center pricePlan"><b>USD
                            ${{formatPrecio(planesCanalWhatsappMensual[0]?.precioPlan)}}</b>/{{'contenidoDashboard.suscripcion.planesInfo.mes'
                          |
                          translate}}</p>
                        <hr class="bg-dark w-100 m-auto" style="height: 2px" />

                        <div class="info-plan">
                          <p class="mt-2">{{'contenidoMain.sectionPlanes.incluye' | translate}}
                            {{formatPrecio(planesCanalWhatsappMensual[0]?.cantidadIncluida)}}
                            {{'contenidoMain.sectionPlanes.mensajes' | translate}}</p>
                          <p>{{'contenidoMain.sectionPlanes.mensajeAdicional' | translate}} USD
                            ${{planesCanalWhatsappMensual[0]?.precioUnidadAdicional}}</p>
                          <p>{{'contenidoMain.sectionPlanes.almacenamiento' | translate}}
                            {{planesCanalWhatsappMensual[0]?.capacidadAlmacenamiento}}MB</p>
                        </div>

                        <button type="button" class="shadow-sm button-basic-outline"
                          (click)="openModalPlans(templatePlans, 'basico', 'whatsapp', 'mensual')">
                          {{'contenidoMain.sectionPlanes.textoBoton' | translate}}
                        </button>
                      </div>
                    </div>

                    <!-- Plan Estandar -->
                    <div class="plan pAdvanced">
                      <div class="titlePlans titlePlanAdvanced">
                        <span>{{'contenidoMain.sectionPlanes.estandar' | translate}}</span>
                      </div>
                      <div class="container">
                        <p class="text-center pricePlan"><b>USD
                            ${{formatPrecio(planesCanalWhatsappMensual[1]?.precioPlan)}}</b>/{{'contenidoDashboard.suscripcion.planesInfo.mes'
                          |
                          translate}}</p>
                        <hr class="bg-dark w-100 m-auto" style="height: 2px" />

                        <div class="info-plan">
                          <p class="mt-2">{{'contenidoMain.sectionPlanes.incluye' | translate}}
                            {{formatPrecio(planesCanalWhatsappMensual[1]?.cantidadIncluida)}}
                            {{'contenidoMain.sectionPlanes.mensajes' | translate}}</p>
                          <p>{{'contenidoMain.sectionPlanes.mensajeAdicional' | translate}} USD
                            ${{planesCanalWhatsappMensual[1]?.precioUnidadAdicional}}</p>
                          <p>{{'contenidoMain.sectionPlanes.almacenamiento' | translate}}
                            {{planesCanalWhatsappMensual[1]?.capacidadAlmacenamiento}}MB</p>
                        </div>

                        <button type="button" class="shadow-sm button-advanced-outline"
                          (click)="openModalPlans(templatePlans, 'estandar', 'whatsapp', 'mensual')">
                          {{'contenidoMain.sectionPlanes.textoBoton' | translate}}
                        </button>
                      </div>
                    </div>

                    <!-- Plan Premium -->
                    <div class="plan pEnterprise">
                      <div class="titlePlans titlePlanEnterprise">
                        <span>{{'contenidoMain.sectionPlanes.premium' | translate}}</span>
                      </div>
                      <div class="container">
                        <p class="text-center pricePlan"><b>USD
                            ${{formatPrecio(planesCanalWhatsappMensual[2]?.precioPlan)}}</b>/{{'contenidoDashboard.suscripcion.planesInfo.mes'
                          |
                          translate}}</p>
                        <hr class="bg-dark w-100 m-auto" style="height: 2px" />

                        <div class="info-plan">
                          <p class="mt-2">{{'contenidoMain.sectionPlanes.incluye' | translate}}
                            {{formatPrecio(planesCanalWhatsappMensual[2]?.cantidadIncluida)}}
                            {{'contenidoMain.sectionPlanes.mensajes' | translate}}</p>
                          <p>{{'contenidoMain.sectionPlanes.mensajeAdicional' | translate}} USD
                            ${{planesCanalWhatsappMensual[2]?.precioUnidadAdicional}}</p>
                          <p>{{'contenidoMain.sectionPlanes.almacenamiento' | translate}}
                            {{planesCanalWhatsappMensual[2]?.capacidadAlmacenamiento}}MB</p>
                        </div>

                        <button type="button" class="shadow-sm button-enterprise-outline"
                          (click)="openModalPlans(templatePlans, 'premium', 'whatsapp', 'mensual')">
                          {{'contenidoMain.sectionPlanes.textoBoton' | translate}}
                        </button>
                      </div>
                    </div>
                  </div>
                </p-tabPanel>

                <p-tabPanel header="{{'contenidoMain.sectionPlanes.anual' | translate}}">
                  <div class="d-flex flex-wrap gap-3 justify-content-center align-items-center my-5">
                    <!-- Plan Basico -->
                    <div class="plan pBasic" style="height: 30rem !important;">
                      <div class="titlePlans titlePlanBasic">
                        <span>{{'contenidoMain.sectionPlanes.basico' | translate}}</span>
                      </div>
                      <div class="container">
                        <p class="text-center pricePlan"><b>USD
                            ${{formatPrecio(planesCanalWhatsappAnual[0]?.precioPlan)}}</b>/{{'contenidoDashboard.suscripcion.planesInfo.anual'
                          |
                          translate}}</p>
                        <hr class="bg-dark w-100 m-auto" style="height: 2px" />

                        <div class="info-plan" style="display: grid;">
                          <p class="mt-2">{{'contenidoMain.sectionPlanes.incluye' | translate}}
                            {{formatPrecio(planesCanalWhatsappAnual[0]?.cantidadIncluida)}}
                            {{'contenidoMain.sectionPlanes.mensajes' | translate}}</p>
                          <p>{{'contenidoMain.sectionPlanes.descuento' | translate}}</p>
                          <p>{{'contenidoMain.sectionPlanes.valorMensual' | translate}} USD
                            ${{valorMensualBasicoWhatsapp}}</p>
                          <p>{{'contenidoMain.sectionPlanes.almacenamiento' | translate}}
                            {{planesCanalWhatsappAnual[0]?.capacidadAlmacenamiento}}MB</p>
                        </div>

                        <button type="button" class="shadow-sm button-basic-outline"
                          (click)="openModalPlanAnual(templatePlanAnual, 'basico', 'whatsapp', 'anual')">
                          {{'contenidoMain.sectionPlanes.textoBoton' | translate}}
                        </button>
                      </div>
                    </div>

                    <!-- Plan Estandar -->
                    <div class="plan pAdvanced" style="height: 30rem !important;">
                      <div class="titlePlans titlePlanAdvanced">
                        <span>{{'contenidoMain.sectionPlanes.estandar' | translate}}</span>
                      </div>
                      <div class="container">
                        <p class="text-center pricePlan"><b>USD
                            ${{formatPrecio(planesCanalWhatsappAnual[1]?.precioPlan)}}</b>/{{'contenidoDashboard.suscripcion.planesInfo.anual'
                          |
                          translate}}</p>
                        <hr class="bg-dark w-100 m-auto" style="height: 2px" />

                        <div class="info-plan" style="display: grid;">
                          <p class="mt-2">{{'contenidoMain.sectionPlanes.incluye' | translate}}
                            {{formatPrecio(planesCanalWhatsappAnual[1]?.cantidadIncluida)}}
                            {{'contenidoMain.sectionPlanes.mensajes' | translate}}</p>
                          <p>{{'contenidoMain.sectionPlanes.descuento' | translate}}</p>
                          <p>{{'contenidoMain.sectionPlanes.valorMensual' | translate}} USD
                            ${{valorMensualEstandarWhatsapp}}</p>
                          <p>{{'contenidoMain.sectionPlanes.almacenamiento' | translate}}
                            {{planesCanalWhatsappAnual[1]?.capacidadAlmacenamiento}}MB</p>
                        </div>

                        <button type="button" class="shadow-sm button-advanced-outline"
                          (click)="openModalPlanAnual(templatePlanAnual, 'estandar', 'whatsapp', 'anual')">
                          {{'contenidoMain.sectionPlanes.textoBoton' | translate}}
                        </button>
                      </div>
                    </div>

                    <!-- Plan Premium -->
                    <div class="plan pEnterprise" style="height: 30rem !important;">
                      <div class="titlePlans titlePlanEnterprise">
                        <span>{{'contenidoMain.sectionPlanes.premium' | translate}}</span>
                      </div>
                      <div class="container">
                        <p class="text-center pricePlan"><b>USD
                            ${{formatPrecio(planesCanalWhatsappAnual[2]?.precioPlan)}}</b>/{{'contenidoDashboard.suscripcion.planesInfo.anual'
                          |
                          translate}}</p>
                        <hr class="bg-dark w-100 m-auto" style="height: 2px" />

                        <div class="info-plan" style="display: grid;">
                          <p class="mt-2">{{'contenidoMain.sectionPlanes.incluye' | translate}}
                            {{formatPrecio(planesCanalWhatsappAnual[2]?.cantidadIncluida)}}
                            {{'contenidoMain.sectionPlanes.mensajes' | translate}} </p>
                          <p>{{'contenidoMain.sectionPlanes.descuento' | translate}} </p>
                          <p>{{'contenidoMain.sectionPlanes.valorMensual' | translate}} USD
                            ${{valorMensualPremiumWhatsapp}}</p>
                          <p>{{'contenidoMain.sectionPlanes.almacenamiento' | translate}}
                            {{planesCanalWhatsappAnual[2]?.capacidadAlmacenamiento}}MB</p>
                        </div>

                        <button type="button" class="shadow-sm button-enterprise-outline"
                          (click)="openModalPlanAnual(templatePlanAnual, 'premium', 'whatsapp', 'anual')">
                          {{'contenidoMain.sectionPlanes.textoBoton' | translate}}
                        </button>
                      </div>
                    </div>
                  </div>
                </p-tabPanel>
              </p-tabView>
            </div>
          </div>
        </div>
        <!-- Planes Planes canal Whatsapp -->
      </div>
    </div>
  </div>

  <div class="container-fluid section8">
    <div class="mxfhd">
      <div class="container d-block m-auto text-center py-5" data-aos="fade-zoom-in" data-aos-easing="ease-out-cubic"
        data-aos-duration="2000">
        <h2 class="title-main">{{'contenidoMain.section8.titulo' | translate}}</h2>

        <div class="mx-fhd mt-3">
          <p-carousel [value]="carouselBlog" [numVisible]="2" [numScroll]="1" [circular]="false"
            [responsiveOptions]="responsiveOptions" [showNavigators]="true" [showIndicators]="false">
            <ng-template let-carouselBlog pTemplate="item">
              <div class="card carousel-blog shadow-sm">
                <img src="{{carouselBlog.imagen}}" class="card-img-top rounded-top-5" alt="Blog">
                <div class="card-body">
                  <h5 class="title-blog-card">{{carouselBlog.title}}</h5>
                  <p class="text-blog-card">{{carouselBlog.text}}</p>

                  <div class="text-center button-blog-align">
                    <button class="button-blog shadow-sm"
                      [routerLink]="['/blog/', carouselBlog.link]">{{carouselBlog.buttonText}}</button>
                  </div>
                </div>
              </div>
            </ng-template>
          </p-carousel>
        </div>

        <div class="btnBlogDiv py-5">
          <button type="button" class="shadow-sm button-basic-outline" [routerLink]="['/blog']">
            {{'contenidoMain.section8.boton' | translate}}
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid section10">
    <div class="mxfhd">
      <div class="row" data-aos="fade-up" data-aos-duration="1500">
        <div class="col-lg d-block m-auto px-5">
          <h2 class="mt-5 mt-md-4 title-main">{{'contenidoMain.section10.titulo' | translate}}</h2>
          <p class="mb-0 my-md-4 text-main">{{'contenidoMain.section10.texto' | translate}}</p>
        </div>
        <div class="col-lg d-block m-auto">
          <img src="../../../assets/images/landing/innovati-logo.png" alt="Nati" class="img-fluid">
        </div>
      </div>
    </div>
  </div>
</section>

<!-- <div class="modal" tabindex="-1" #modal id="modal-nati">
  <div [ngClass]="{'modal-dialog': !cambiarClases, 'modal-dialog-center': cambiarClases}">
    <div class="modal-content">
        <div class="modal-header">
          <div class="d-flex justify-content-end align-items-center">
            <button type="button" class="btn-fullscreen" (click)="maximizarModal()"><img [src]="iconActual" alt="" style="width: 125%;"></button>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="ocultarModal()" style="margin-left: -1%;"></button>
          </div>
        </div>
      <div class="modal-body">
        <div class="iframe-container" [ngClass]="{'iframe-container': !cambiarClases, 'iframe-container-max': cambiarClases}">
          <div class="iframe-wrapper">
            <iframe src="https://uat-app.nati.ai/" allow="microphone" frameborder="0" scrolling="no" width="100%" height="720"></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->

<!-- Modal con iframe de app.nati.ai -->
<!-- <div class="modal" tabindex="-1" #modal>
  <div
    [ngClass]="{'modal-dialog': !cambiarClases, 'modal-dialog-center': cambiarClases, 'modal-dialog-mobile': isMobile}">
    <div class="modal-content" [ngClass]="{'modal-content-mobile': isMobile}">
      <div class="modal-header">
        <div class="d-flex justify-content-end align-items-center container-icons-modal">
          <img *ngIf="!isMobile" [src]="iconActual" alt="" style="cursor: pointer;" (click)="maximizarModal()">
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="ocultarModal()"
            style="margin-left: -1%;"></button>
        </div>
      </div>
      <div class="modal-body">
        <div
          [ngClass]="{'iframe-container': !cambiarClases, 'iframe-container-max': cambiarClases, 'iframe-container-mobile': isMobile}">
          <div [ngClass]="{'iframe-wrapper': !isMobile, 'iframe-wrapper-mobile': isMobile}">
            <iframe src="https://app.nati.ai/a7f374d8-45c2-4eaa-8f04-9e7861914049" allow="microphone" frameborder="0"
              scrolling="no" width="100%" height="720"></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->

<!-- <div mat-fab class="sticky-button scroll-to-top" (click)="mostrarModal()" *ngIf="pageYoffset > 0" (mouseenter)="showSticky = true" (mouseleave)="showSticky = false" >
  <img id="img-sticky-btn" (click)="mostrarModal()" src="../../../assets/images/landing/nati-buble.gif" alt="Nati" frameborder="0"
    width="100%" height="100%" allowfullscreen>
    <div id="sticky-content" *ngIf="showSticky && !isMobile">
    <p id="textosticky">{{'contenidoMain.stickyButton.texto' | translate}}</p>
  </div>
</div> -->

<div mat-fab class="sticky-button scroll-to-top" *ngIf="pageYoffset > 0" (mouseenter)="showSticky = true"
  (mouseleave)="showSticky = false">
  <a href="https://app.nati.ai/a7f374d8-45c2-4eaa-8f04-9e7861914049" target="_blank">
    <img id="img-sticky-btn" src="../../../assets/images/landing/nati-buble.gif" alt="Nati" frameborder="0"
      width="100%" height="100%" allowfullscreen>
  </a>
  <div id="sticky-content" *ngIf="showSticky && !isMobile">
    <p id="textosticky">{{'contenidoMain.stickyButton.texto' | translate}}</p>
  </div>
</div>


<button type="button" class="scroll-to-top left-position" (click)="scrollToTop()" *ngIf="pageYoffset > 0">
  <span class="arrow up"></span>
</button>